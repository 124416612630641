import { FC, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';
import ContactForm from '../../Organisms/Form/ContactForm';
import Footer from '../../Atoms/Footer';
import PageTitle from '../../Organisms/Layout/PageTitle';
import { useTopScroll } from '../../../hooks/useTopScroll';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const Contact:FC<Props> = ({ setActive }) => {
  useTopScroll();
  useEffect(() => {
    setActive('contact');
    document.title = 'GrantDrive | トライアル申し込み・お問い合わせ';
  }, [setActive]);

  return (
    <>
      <PageTitle odd height={80} center>
        <Grid.Row textAlign="center" verticalAlign="middle" css={serviceTitile}>
          トライアル申し込み・お問い合わせ
        </Grid.Row>
      </PageTitle>
      <div css={containerColor}>
        <PageTitle height={120} center odd={false}>
          <Grid.Row textAlign="center" verticalAlign="middle" css={cntactDetails}>
            GrantDriveに関するお問い合わせおよびトライル利用の申し込みについては、
            <br />
            以下の項目にご入力ください。「※」は入力必須項目です。
          </Grid.Row>
        </PageTitle>
        <ContactForm />
        <Footer minHeihght="50px" />
      </div>
    </>
  );
};

const cntactDetails = css({
  fontSize: '14px',
  lineHeight: '1.6',
});

const serviceTitile = css({
  fontSize: '20px',
});

const containerColor = css({
  background: '#fff',
});
export default Contact;
