import { css } from '@emotion/react';
import { useState, FC, useEffect } from 'react';
import {
  Grid, Form, Segment,
} from 'semantic-ui-react';

import { useHistory } from 'react-router-dom';
import DefaultButton from '../../Atoms/button/DefaultButton';
import AuthForm from '../../Atoms/Form/AuthForm';
import AuthText from '../../Atoms/auth/AuthText';
import AuthTitle from '../../Atoms/auth/AuthTitle';
import ErrorMessage from '../../Atoms/ErrorMessage';
import { URL } from '../../../util';
import { ChangePassword } from '../../../Repository/Cognito';
import PasswordPolicyMessage from '../../Atoms/PasswordPolicyMessage';

type historyProps = {
  email: string
}
const ChangePasswordForm: FC = () => {
  const [code, setCode] = useState('');
  const history = useHistory<historyProps>();
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [err, setErr] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!history.location.state
      || !history.location.state.email) {
      history.push(URL.login);
    }
    document.title = 'GrantDrive | パスワードリセット';
  }, [history]);

  const changePassword = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setErr('');

    if (code === '') {
      setErr('認証コードが入力されていません。');

      return;
    }

    if (password !== rePassword) {
      setErr('パスワードが一致していません。');

      return;
    }
    const { email } = history.location.state;
    ChangePassword({
      password, code, setLoading, history, email, setErr,
    });
  };

  return (
    <Grid.Column textAlign="justified">
      <Form css={segmentPosition} size="mini">
        <Segment size="large">
          <AuthTitle title="パスワードリセット" />
          <AuthText marginBt="20px" marginTp="10px">
            通知された認証コードと新しいパスワードを入力してください
          </AuthText>
          {err && (<ErrorMessage errMessage={err} />)}
          <AuthForm label="認証コード" value={code} setValue={setCode} />
          <AuthForm label="新しいパスワード" value={password} setValue={setPassword} password />
          <AuthForm label="新しいパスワード（再入力）" value={rePassword} setValue={setRePassword} password />

          <div css={butonMargin}>
            <DefaultButton
              text="パスワード変更"
              marginTp="50px"
              onClinckFunc={(e) => changePassword(e)}
              disabled={loading}
            />
          </div>

          <PasswordPolicyMessage />

        </Segment>
      </Form>
    </Grid.Column>
  );
};

const segmentPosition = css({
  marginTop: '50px',
});

const butonMargin = css({
  marginBottom: '50px',
});
export default ChangePasswordForm;
