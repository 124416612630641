import React, { useEffect } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import AuthHeader from '../../Organisms/header/AuthHeader';
import IndexLayout from '../../Organisms/IndexLayout';
import AuthText from '../../Atoms/auth/AuthText';
import AuthTitle from '../../Atoms/auth/AuthTitle';
import Footer from '../../Atoms/Footer';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { URL } from '../../../util';

const ChangePasswordDone:React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    document.title = 'GrantDrive | パスワードリセット 完了';
  }, []);

  return (
    <>
      <AuthHeader />
      <IndexLayout odd height={850}>
        <Grid.Column textAlign="justified" css={segmentPosition}>
          <Segment size="large">
            <AuthTitle title="パスワード変更完了" />
            <AuthText marginBt="20px" marginTp="10px">
              パスワード変更が完了しました。
            </AuthText>
            <div css={buttonPosition}>
              <DefaultButton
                text="ログインページへ戻る"
                marginTp="100px"
                marginBt="100px"
                width="185px"
                onClinckFunc={(e) => {
                  e.preventDefault();
                  history.push(URL.login);
                }}
              />
            </div>
          </Segment>
        </Grid.Column>
      </IndexLayout>
      <Footer odd />
    </>
  );
};

const segmentPosition = css({
  marginTop: '50px',
});

const buttonPosition = css({
  marginBottom: '50px',
});
export default ChangePasswordDone;
