import { FC, useEffect } from 'react';
import AccountContextProvider from '../../../context/AccountProvider';
import { getCntractInfo } from '../../../Repository/ContactRepository';
import Footer from '../../Atoms/Footer';
import Account from '../dashBoard/Account';
import PasswordSetting from './PasswordSetting';
import ServiceStop from './ServiceStop';
import SettingMenu from './SettingMenu';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>,
  item: string,
  setItem: React.Dispatch<React.SetStateAction<string>>,
  isNotGetAccountStatus: boolean,
  contactInfo: getCntractInfo,
  loading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  err: string
}

const SettingWrap:FC<Props> = ({
  setActive, setItem, item, isNotGetAccountStatus, contactInfo, loading, setIsLoading, err,
}) => {
  useEffect(() => {
    document.title = 'GrantDrive | お客様ポータル | 設定';
  }, []);

  return (
    <>
      <SettingMenu setItem={setItem} item={item} isNotGetAccountStatus={isNotGetAccountStatus} />
      { item === 'serviceStop'
        && (
        <ServiceStop
          setActive={setActive}
          contractInfo={contactInfo}
          loading={loading}
          setIsLoading={setIsLoading}
          err={err}
        />
        )}
      { item === 'account' && (
        <AccountContextProvider>
          <Account />
        </AccountContextProvider>
      ) }
      { item === 'password' && (<PasswordSetting />) }
      { item === 'password' && <Footer odd minHeihght="0px" /> }
      { item === 'serviceStop' && <Footer odd minHeihght="0px" /> }
      { item === 'account' && <Footer odd minHeihght="0px" /> }
    </>
  );
};

export default SettingWrap;
