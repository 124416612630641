/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { config, URL } from '../util';

type SiginInProps = {
  email: string;
  password: string;
  setIdToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setErr: React.Dispatch<React.SetStateAction<string | undefined>>;
  history: any;
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>;
  userName: string;
  setTenantAlias: React.Dispatch<React.SetStateAction<string>>;
};

type FogotPasswordProps = {
  history: any;
  email: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type ChangePasswordProps = {
  history: any;
  password: string;
  code: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
  setErr: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const userPool = new CognitoUserPool({
  UserPoolId: config.awsConfiguration.UserPoolId,
  ClientId: config.awsConfiguration.ClientId,
});

export const CognitoLogin = ({
  email,
  password,
  setAccessToken,
  setErr,
  setIdToken,
  setIsLogin,
  history,
  setUserName,
  userName,
  setTenantAlias,
}: SiginInProps): void => {
  setErr('');
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      const decodeToken = result.getIdToken().decodePayload();
      setTenantAlias(decodeToken['custom:tenant']);
      setAccessToken(result.getAccessToken().getJwtToken());
      setIdToken(result.getIdToken().getJwtToken());
      setIsLogin(true);
      setUserName(userName);
      history.push(URL.dashboard);
    },
    onFailure: (err) => {
      setErr(errorhandle(err));
    },
  });
};

export const FogotPassword = ({ history, email, setLoading }: FogotPasswordProps): void => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  cognitoUser.forgotPassword({
    onSuccess: () => {
      setLoading(false);
    },
    onFailure: () => {
      setLoading(false);
    },
    inputVerificationCode: () => {
      history.push(URL.passwordChange, { email });
      setLoading(false);
    },
  });
};

export const ChangePassword = ({
  password,
  code,
  setLoading,
  history,
  email,
  setErr,
}: ChangePasswordProps): void => {
  setErr('');
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  cognitoUser.confirmPassword(code, password, {
    onSuccess: () => {
      history.push(URL.passwordChangeDone);
      setLoading(false);
    },
    onFailure: (err) => {
      setErr(errorhandle(err));
      setLoading(false);
    },
  });
};

export const CognitoLogout = (userName: string, history: any): void => {
  const cognitoUser = new CognitoUser({
    Username: userName,
    Pool: userPool,
  });
  cognitoUser.signOut(() => {
    localStorage.clear();
    history.push(URL.index);
  });
};

const errorhandle = (err: any): string => {
  switch (err.code) {
    case 'NotAuthorizedException':
      if (err.message === 'Password attempts exceeded.') {
        return '認証が連続して失敗しました。しばらくしてからログインしてください。';
      }

      return 'ユーザー名またはパスワードが違います。';
    case 'UserNotFoundException':
      return 'ユーザー名またはパスワードが違います。';
    case 'InvalidParameterException':
      return 'ユーザー名またはパスワードが違います。';
    case 'InvalidPasswordException':
      return 'パスワードポリシーをご確認のうえ、パスワードを設定してください。';
    default:
      return 'エラーが発生しました。しばらくしてからお試しください。';
  }
};
