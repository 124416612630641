import { css } from '@emotion/react/macro';
import { FC } from 'react';
import { Image } from 'semantic-ui-react';
import footerLogo from '../../assets/GrantDrive_logo_footer.svg';

type Props ={
  odd?: boolean
  height?: string
  minHeihght?:string
}

const Footer:FC<Props> = ({ odd = false, height = '70px', minHeihght = '60vh' }) => {
  const background = odd ? '#EDF2FA' : '#fff';
  const topLine = odd ? '' : 'border-top: 1px solid rgba(0, 0, 0, 0.25);';
  const footerStyle = css`
  ${topLine}
  height: ${height};
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
`;
  const footerWrapper = css`
  background: ${background};
  min-height: ${minHeihght};
  position: relative;
  padding-bottom: 70px;
  box-sizing: border-box;
  `;

  return (
    <footer css={footerWrapper}>
      <div css={footerStyle}>
        <small>
          © 2021
          <Image src={footerLogo} css={imageStyle} />
        </small>
      </div>
    </footer>
  );
};

const imageStyle = css`
  display: inline !important;
  padding-bottom: 3px;
  margin-left: 4px;
`;

export default Footer;
