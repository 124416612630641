/* eslint-disable arrow-body-style */
import { FC, useState } from 'react';
import { css } from '@emotion/react/macro';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AccounstsResponce, AccountRepository } from '../../Repository/AccountRepository';
import DeleteDialog from '../Atoms/DeleteDialog';
import { URL } from '../../util';

type Props = {
  users: AccounstsResponce
  me: string | undefined
  idToken: string | undefined,
  tenantAlias: string
}
const AccountsTable:FC<Props> = ({
  users, me, idToken, tenantAlias,
}) => {
  const [modalopen, setModalOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteName, setDeleteName] = useState('');
  const [id, setId] = useState('');
  const deleteHandle = (name: string, deleteId: string) => {
    setId(deleteId);
    setDeleteName(name);

    setModalOpen(true);
  };
  const userDelete = () => {
    setLoading(true);
    void AccountRepository(tenantAlias, idToken)
      .deleteUser(id)
      .then((result) => {
        if (result) {
          setLoading(false);
          setIsDeleted(true);
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <>
      <DeleteDialog
        onClickFunc={userDelete}
        open={modalopen}
        setOpen={setModalOpen}
        content={`${deleteName}を削除しますか？`}
        loading={loading}
        isDeleted={isDeleted}
      />
      {
        users[0].userId !== '' && (
          <table css={TableStyle}>
            <thead>
              <tr>
                <th>氏名</th>
                <th>部署</th>
                <th>電話番号</th>
                <th>メールアドレス</th>
                <th>削除</th>
              </tr>
            </thead>
            <tbody>
              {
            users.map((user) => (
              <tr key={user.userId}>
                <td>
                  <Link to={`${URL.settings}/${tenantAlias}/${user.userId}`}>
                    {user.name}
                  </Link>
                </td>
                <td>{user.departmentName}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.email}</td>
                <td>
                  {
                  me !== user.email && (
                    <Icon
                      name="trash alternate"
                      size="big"
                      link
                      onClick={() => deleteHandle(user.name, user.userId)}
                    />
                  )
                  }
                </td>
              </tr>
            ))
            }
            </tbody>
          </table>
        )
      }
    </>
  );
};

const TableStyle = css`
  background: #FFFFFF;
  margin: 0 10px 20px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.5rem;
  text-align: left;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0;
  font-family: "Noto Sans JP";

  thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

  thead > tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  thead > tr > th {
  background-color: #F7FAFF;
  cursor: auto;
  text-align: center;
  color: #393939;
  padding: 7px;
  vertical-align: inherit;
  font-size: 13px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  border-left: none;
  }
  tbody {
  text-align: inherit;
  vertical-align: inherit;
  }
  tbody > tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  tbody > tr> td {
  padding: 0.78571429em 0.78571429em;
  text-align: inherit;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  font-size: 13px;
  }
  th:nth-of-type(1) {
    width: 15%;
  }
  th:nth-of-type(2) {
  width: 15%;
  }
  th:nth-of-type(3) {
  width: 10%;
  }
  th:nth-of-type(4) {
    width: 12%;
  }
  th:nth-of-type(5) {
  width: 7%;
  text-align: center;
  }
  td:nth-of-type(5) {
    width: 7%;
    text-align: center;
  }
`;

export default AccountsTable;
