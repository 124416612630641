/* eslint-disable camelcase */
import { createAxiosInstance } from './repository';

type serviceStateAPiType = {
  grantDrive_status: number;
  box_api_status: number;
};

type getEventsStateApiType = {
  events: {
    created_at: string;
    file_name: string;
    created_user: string;
    acquire_at: string;
  }[];
};

type serviceResponce = {
  grantDriveStatus: number;
  boxApiAtatus: number;
};
export type eventsResponce = {
  createtAt: string;
  fileName: string;
  createdUser: string;
}[];
type StateRepositoryType = {
  getServiceState: (tenantAlias: string) => Promise<serviceResponce>;
  getEventsState: (tenantAlias: string) => Promise<eventsResponce>;
};
export const StateRepository = (idtoken?: string): StateRepositoryType => {
  const instance = createAxiosInstance(idtoken);

  const getServiceState = async (tenantAlias: string): Promise<serviceResponce> => {
    const { data } = await instance.get<serviceStateAPiType>(`${tenantAlias}/boxevents`);
    const responce = {
      grantDriveStatus: data.grantDrive_status,
      boxApiAtatus: data.box_api_status,
    };

    return responce;
  };

  const getEventsState = async (tenantAlias: string): Promise<eventsResponce> => {
    const { data } = await instance.get<getEventsStateApiType>(
      `${tenantAlias}/boxeventslog?limit=10&sort=desc`,
    );
    const responce = data.events.map((e) => ({
      createtAt: e.created_at,
      fileName: e.file_name,
      createdUser: e.created_user,
    }));

    return responce;
  };

  return { getServiceState, getEventsState };
};
