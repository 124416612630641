import { FC } from 'react';
import { Menu } from 'semantic-ui-react';
import IndexLayout from '../IndexLayout';

type Props ={
  setItem: React.Dispatch<React.SetStateAction<string>>
  item: string,
  isNotGetAccountStatus: boolean,
}

const clickColor = 'rgb(232, 241, 251)';

const SettingMenu:FC<Props> = ({ setItem, item, isNotGetAccountStatus }) => (
  <IndexLayout odd={false} height={40} center text setting>
    <Menu fluid widths={3} tabular style={{ borderBottom: 'none' }}>
      <Menu.Item
        onClick={() => setItem('password')}
        style={{
          background: item === 'password' && clickColor,
          color: item === 'password' && '#224EA8',
        }}
        disabled={isNotGetAccountStatus}
      >
        サービス設定
      </Menu.Item>
      <Menu.Item
        as="a"
        onClick={() => setItem('account')}
        style={{
          background: item === 'account' && clickColor,
          color: item === 'account' && '#224EA8',
        }}
        disabled={isNotGetAccountStatus}
      >
        アカウント管理
      </Menu.Item>
      <Menu.Item
        as="a"
        onClick={() => setItem('serviceStop')}
        style={{
          background: item === 'serviceStop' && clickColor,
          color: item === 'serviceStop' && '#224EA8',
        }}
      >
        利用解除申込
      </Menu.Item>
    </Menu>
  </IndexLayout>
);

export default SettingMenu;
