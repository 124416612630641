import { css } from '@emotion/react/macro';
import { FC } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import ServiceTitle from '../../Atoms/entrance/ServiceTitle';
import { tableStyle, tableStyle2 } from './costTable.css';
import EnranceButton from '../../Atoms/button/EnranceButton';
import EntranceTitle from '../../Atoms/entrance/EntranceTitle';

const MainCost: FC = () => (
  <div css={containreMargin}>
    <Container style={{ width: '1024px' }}>
      <Grid textAlign="center">
        <EntranceTitle title="価格について" />
        <Grid.Row textAlign="center">
          <p>初期費用およびサービス費用について、下記の通りになります。</p>
        </Grid.Row>
        <Grid.Row css={costTextStyle}>
          <ServiceTitle
            text="初期費用"
            marginTp="50px"
            mainCost="サービス利用開始時に発生する、各種設定・導入サポート費用となります。"
          />
        </Grid.Row>
        <Grid.Row>
          <table css={tableStyle}>
            <thead style={{ padding: '20px' }}>
              <tr>
                <th css={headerStyle}>分類・ライセンス種別</th>
                <th css={headerStyle}>標準価格(税別)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>初期費用</td>
                <td>50,000円</td>
              </tr>
            </tbody>
          </table>
        </Grid.Row>
        <Grid.Row css={gridStyle}>
          <ServiceTitle
            text="サービス利用費用"
            marginTp="50px"
            mainCost={(
              <>
                月額サービス利用費用は、
                <span css={spanStyle}>
                  基本料金+ユーザー数量料金
                </span>
                の合計がご請求費用となります。
              </>
            )}
          />
        </Grid.Row>
        <Grid.Row>
          <table css={tableStyle2}>
            <thead style={{ padding: '20px' }}>
              <tr>
                <th css={headerStyle}>分類</th>
                <th css={headerStyle}>ライセンス種別</th>
                <th css={headerStyle}>標準価格(税別)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td css={trStyle}>基本料金</td>
                <td>テナントあたり基本料金</td>
                <td>5,000円</td>
              </tr>
              <tr>
                <td css={trStyle}>ユーザー数料金</td>
                <td>ユーザーあたり月額費用</td>
                <td>250円</td>
              </tr>
            </tbody>
          </table>
        </Grid.Row>
        <Grid.Row textAlign="left" css={textStyle}>
          ※最低利用ユーザー数は 5 ユーザーとなります。上記サービス利用費用は月額料金となります。
          <br />
          ※ボリュームディスカウントについては、弊社までお問い合わせください。
        </Grid.Row>
        <Grid.Row>
          <EnranceButton
            text="トライアル申し込み・お問い合わせ"
            marginTp="50px"
            width="100%"
          />
        </Grid.Row>
      </Grid>
    </Container>
  </div>
);

const costTextStyle = css`
margin-top: 25px;
`;

const spanStyle = css`
 color: #FCC03A
`;

const gridStyle = css`
  margin-top: 25px;
`;

const textStyle = css`
  justify-content: unset !important;
  font-size: 14px;
  line-height: 1.8;
`;

const trStyle = css`
  background: #CDD5E3 !important;
  color: #224EA8;
`;
const headerStyle = css`
  font-size: 12px;
  margin: 5px;
  background: #CDD5E3 !important;
`;
const containreMargin = css`
  background-color: rgb(232, 241, 251);
  margin-bottom: 20px;
  padding-bottom: 100px;
`;
export default MainCost;
