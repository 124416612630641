/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {
  FC, useState, useContext, useEffect,
} from 'react';

import PortalHeader from '../../Organisms/header/PortalHeader';
import ServiceStop from '../../Organisms/setting/ServiceStop';
import { Context } from '../../../context/ContextProvider';
import { initialGetCntractInfo } from '../../../hooks';
import SettingWrap from '../../Organisms/setting/SettingWrap';
import { getCntractInfo, ContactRepository } from '../../../Repository/ContactRepository';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const Setting: FC<Props> = ({ active, setActive }) => {
  const initItem = window.location.search === '?account' ? 'account' : 'password';
  const [item, setItem] = useState(initItem);
  const [loading, setIsLoading] = useState(false);
  const { idtoken, tenantAlias } = useContext(Context);
  const [isNotGetAccountStatus, setIsNotGetAccountStatus] = useState(false);
  const [contractinfo, setContractInfo] = useState<getCntractInfo>(initialGetCntractInfo);
  const [err, setErr] = useState('');

  useEffect(() => {
    setActive('settings');
    ContactRepository(idtoken).getContractInfo(tenantAlias)
      .then((data) => {
        if (!data) {
          return;
        }
        if (!(data.tenantStatus === 12 || data.tenantStatus === 22)) {
          setItem('serviceStop');
          setIsNotGetAccountStatus(true);
        }
        setContractInfo(data);
        setIsLoading(false);
      })
      .catch((e:any) => {
        if (e.status) {
          if (e.response.status === 403 || e.response.status === 404) {
            setErr('');
            setIsLoading(false);

            return;
          }
        }
        setErr('エラーが発生しました。しばらくしてからお試しください。');
        setIsLoading(false);
      });
  }, [idtoken, setActive, tenantAlias]);

  return (
    <>
      <PortalHeader active={active} setActive={setActive} />
      {
        (loading && isNotGetAccountStatus)
          ? (
            <ServiceStop
              setActive={setActive}
              contractInfo={contractinfo}
              loading={loading}
              setIsLoading={setIsLoading}
              err={err}
            />
          )
          : (
            <SettingWrap
              item={item}
              setActive={setActive}
              setItem={setItem}
              isNotGetAccountStatus={isNotGetAccountStatus}
              contactInfo={contractinfo}
              loading={loading}
              setIsLoading={setIsLoading}
              err={err}
            />
          )
      }
      )
    </>
  );
};

export default Setting;
