import { useEffect, useState } from 'react';
import { StateRepository } from '../Repository/StateRepository';

type serviceStateType = {
  grantDriveStatus: number;
  boxApiAtatus: number;
};
type useGetServiceStateType = {
  serviceState: serviceStateType;
  err: string;
};
const initialServiceInfo: serviceStateType = {
  grantDriveStatus: 1,
  boxApiAtatus: 1,
};

export const useGetServiceState = (
  idtoken: string | undefined,
  tenantAlias: string,
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>,
): useGetServiceStateType => {
  const [serviceState, setServiceState] = useState<serviceStateType>(initialServiceInfo);
  const [err, setError] = useState('');

  useEffect(() => {
    setIsloading(true);
    void StateRepository(idtoken)
      .getServiceState(tenantAlias)
      .then(({ grantDriveStatus, boxApiAtatus }) => {
        setServiceState({
          grantDriveStatus,
          boxApiAtatus,
        });
        setIsloading(false);
      })
      .catch((_) => {
        setError('エラーが発生');
        setIsloading(false);
      });
  }, [idtoken, setIsloading, tenantAlias]);

  return { serviceState, err };
};
