import { FC, useContext, useState } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Segment } from 'semantic-ui-react';
import { Context } from '../../../context/ContextProvider';
import Loading from '../../Atoms/Loading';
import ServiceStateTable from '../../Molecules/ServiceStateTable';
import { useGetServiceState } from '../../../hooks';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';

const ServiceState:FC = () => {
  const { idtoken, tenantAlias } = useContext(Context);
  const [loading, setIsLoading] = useState(true);
  const { serviceState, err } = useGetServiceState(idtoken, tenantAlias, setIsLoading);

  return (
    <Container css={segmntMargin}>
      <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
        <SegmentHeader rightText="GrantDriveサービス状況">
          サービス稼働状況
        </SegmentHeader>
        {err && (<p>{err}</p>)}
        {loading && (<Loading />)}
        {!err && !loading && (
          <ServiceStateTable
            boxApiStatus={serviceState.boxApiAtatus}
            grantDriveStatus={serviceState.grantDriveStatus}
          />
        )}
      </Segment>
    </Container>
  );
};
const segmntMargin = css({
  marginTop: '15px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

export default ServiceState;
