/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-restricted-globals */

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { config } from '../util';

import { userPool } from './Cognito';

export const baseDomain = config.baseurl;
export const baseURL = `${baseDomain}/v1/tenant`;
// export const baseURL = '/v1/tenant';
export const createAxiosInstance = (idToken = ''): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.request.use((req: AxiosRequestConfig) => {
    if (idToken === '') {
      const user = userPool.getCurrentUser();
      if (user) {
        user.getSession((error: null, session: CognitoUserSession) => {
          if (error) {
            location.href = '/login';
          }
          if (session === null) {
            location.href = '/login';
          }
          const decodeToken = session.getIdToken().decodePayload();
          const tenant = decodeToken['custom:tenant'];
          const idToken = session.getIdToken().getJwtToken();
          req.headers.GDAuthorization = `Bearer ${idToken}`;
          if (req.url === '/') {
            req.url = `${tenant}`;
          } else {
            req.url = `${tenant}${req.url}`;
          }
        });
      }
    } else {
      req.headers.GDAuthorization = `Bearer ${idToken}`;
    }

    return req;
  });

  return instance;
};
