import { FC, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import EntranceLayout from '../components/Organisms/Layout/EntranceLayout';
import Login from '../components/Pages/auth/Login';
import Contact from '../components/Pages/entrance/Contact';
import Service from '../components/Pages/entrance/Service';
import Index from '../components/Pages/Index';
import PasswordReset from '../components/Pages/auth/PasswordReset';
import ChangePassword from '../components/Pages/auth/ChangePassword';
import ChangePasswordDone from '../components/Pages/auth/ChangePasswordDone';
import { URL } from '../util';
import ContactDone from '../components/Pages/entrance/ContactDone';
import NotFound from '../components/Pages/entrance/NotFound';
import AddAccount from '../components/Pages/portal/AddAccount';
import ChangeAccountInfo from '../components/Pages/portal/ChangeAccountInfo';
import Credit from '../components/Pages/portal/Credit';
import CreditChange from '../components/Pages/portal/CreditChange';
import CreditDone from '../components/Pages/portal/CreditDone';
import Dashboard from '../components/Pages/portal/Dashboard';
import Setting from '../components/Pages/portal/Setting';
import AuthCheck from '../components/Pages/auth/AuthCheck';
import SessionCheck from '../components/Pages/auth/SessionCheck';

const Router: FC = () => {
  const [entranceActive, setEntranceActive] = useState('');
  const [active, setActive] = useState('dashboard');

  return (
    <Switch>
      <Route exact path={URL.index}>
        <SessionCheck>
          <EntranceLayout active={entranceActive} setActive={setEntranceActive}>
            <Index setActive={setEntranceActive} />
          </EntranceLayout>
        </SessionCheck>
      </Route>
      <Route exact path={URL.service}>
        <SessionCheck>
          <EntranceLayout active={entranceActive} setActive={setEntranceActive}>
            <Service setActive={setEntranceActive} />
          </EntranceLayout>
        </SessionCheck>
      </Route>
      <Route exact path={URL.contact}>
        <SessionCheck>
          <EntranceLayout active={entranceActive} setActive={setEntranceActive}>
            <Contact setActive={setEntranceActive} />
          </EntranceLayout>
        </SessionCheck>
      </Route>
      <Route exact path={URL.contactDone}>
        <EntranceLayout active={entranceActive} setActive={setEntranceActive}>
          <ContactDone setActive={setEntranceActive} />
        </EntranceLayout>
      </Route>
      <Route exact path={URL.login}>
        <Login />
      </Route>
      <Route exact path={URL.passwordReset}>
        <PasswordReset />
      </Route>
      <Route exact path={URL.passwordChange}>
        <ChangePassword />
      </Route>
      <Route exact path={URL.passwordChangeDone}>
        <ChangePasswordDone />
      </Route>
      <Route exact path={URL.dashboard}>
        <AuthCheck>
          <Dashboard active={active} setActive={setActive} />
        </AuthCheck>
      </Route>
      <Route exact path={URL.settings}>
        <AuthCheck>
          <Setting setActive={setActive} active={active} />
        </AuthCheck>
      </Route>
      <Route exact path={URL.addAccount}>
        <AuthCheck>
          <AddAccount setActive={setActive} active={active} />
        </AuthCheck>
      </Route>
      <Route exact path={URL.credit}>
        <AuthCheck>
          <Credit setActive={setActive} active={active} />
        </AuthCheck>
      </Route>
      <Route exact path={URL.creditChange}>
        <AuthCheck>
          <CreditChange setActive={setActive} active={active} />
        </AuthCheck>
      </Route>
      <Route exact path={URL.creditDone}>
        <AuthCheck>
          <CreditDone setActive={setActive} />
        </AuthCheck>
      </Route>
      <Route exact path={`${URL.settings}/:tenant/:id`}>
        <AuthCheck>
          <ChangeAccountInfo active={active} setActive={setActive} />
        </AuthCheck>
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
