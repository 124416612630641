/* eslint-disable consistent-return */
import { FC } from 'react';
import { css } from '@emotion/react/macro';
import { Link } from 'react-router-dom';
import { Button, Icon, Message } from 'semantic-ui-react';

type Props = {
  tenantStatusName: string,
  tenantContractStart: string,
  tenantContractEnd?: string,
  tenantMaximumAccount: string,
  tenantContractType: string,
  tenantStatus: number,
  isStop?: boolean
}

const ContractInfoTable:FC<Props> = ({
  tenantStatusName, tenantContractEnd, tenantContractStart,
  tenantContractType, tenantMaximumAccount, tenantStatus, isStop = false,
}) => {
  const tableStyle = isStop ? stateTableStyle : isStopStyle;
  const stripeRequest = () => {
    if (Number(tenantContractType) === 30) {
      return (<td />);
    }
    if (tenantStatus === 12 || tenantStatus === 19
        || tenantStatus === 30 || tenantStatus === 31) {
      return (
        <td>
          <Link to="/portal/credit">
            <Button size="small" color="blue" basic circular>
              <div css={buttonStyle}>
                利用申し込み
              </div>
            </Button>
          </Link>
        </td>
      );
    }

    return (<td />);
  };
  const changeStripe = () => {
    if (isStop && tenantStatus === 22 && tenantContractType !== '請求書払い') {
      return (
        <td>
          <Link to="/portal/credit-change">
            <Button size="small" color="blue" basic circular>
              <div css={buttonStyle}>
                変更
              </div>
            </Button>
          </Link>
        </td>
      );
    }
  };

  return (
    <>
      <table css={tableStyle}>
        <tbody>
          <tr>
            <td css={tdWhite}>
              ステータス
            </td>
            <td>
              <span css={statusStyle}>
                {tenantStatusName}
              </span>
            </td>
            {stripeRequest()}
          </tr>
          <tr>
            <td css={tdWhite}>
              契約期間
            </td>
            <td>
              {' '}
              <span css={statusColor}>
                {tenantContractStart}
                {' '}
                ~
                {tenantContractEnd && tenantContractEnd}
              </span>
            </td>
            <td />
          </tr>
          {(tenantStatus === 22 && tenantContractType === 'Stripe')
            && (
              <tr>
                <td css={tdWhite}>
                  ご契約ユーザー数
                </td>
                <td>{tenantMaximumAccount}</td>
                <td />
              </tr>
            )}
          <tr>
            <td css={tdWhite}>
              お支払い方法
            </td>
            <td>{tenantContractType}</td>
            {changeStripe()}
          </tr>
        </tbody>
      </table>
      {(tenantStatus === 20 || tenantStatus === 21) && (
        <Message basic color="red" css={{ marginTop: 0 }}>
          <Icon name="exclamation circle" />
          本番環境構築中です。しばらくたってからアクセスしてください。
        </Message>
      )}
    </>
  );
};

const tdWhite = css({
  color: '#898989',
});

const statusStyle = css({
  borderRadius: '100px',
  marginRight: '5%',
  color: '#19B919',
  border: '2px solid #19B919',
  'background-color': '#efffef',
  padding: '5px 14px',
  letterSpacing: '.1em',
  position: 'relative',
  top: '0.5px',
});
const statusColor = css({
  color: '#19B919',
});
const buttonStyle = css`
  white-space: .1em;
  letter-spacing: .1em;
`;

const stateTableStyle = css`
  border: none;  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  box-shadow: none;
  font-size: 1em;
  width: 100%;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0;
  margin: 3em 0;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tbody > tr {
    display: table-row;
    height: 5rem;
    vertical-align: inherit;
    border-color: inherit;
    font-size: 15px;
    white-space: nowrap;
    margin: 5px;
  }
  td {
    padding: 0.78571429em 0.78571429em;
    transition: background 0.1s ease, color 0.1s ease;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  tr:nth-of-type(4) > td {
    border: none;
  }

  td:nth-of-type(1) {
    text-align: right;
    vertical-align: middle;
    width: 18.75%;
    color: #898989;
  }
  td:nth-of-type(3){
    text-align: right;
  }
`;

const isStopStyle = css`
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  background: transparent;
  border: none;
  box-shadow: none;
  font-size: 1em;
  width: 100%;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  margin: 0em;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tr {
    display: table-row;
    height: 5rem;
    vertical-align: inherit;
    border-color: inherit;
    font-size: 15px;
    white-space: nowrap;
    margin: 5px;
  }
  td {
    padding: 1em 1em;
    transition: background 0.1s ease, color 0.1s ease;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  td:nth-of-type(1) {
    text-align: right;
    vertical-align: middle;
    background: transparent;
    width: 18.75%;
    border-top: none;
    color: #898989;
  }
  td:nth-of-type(3){
    text-align: right;
  }
  tr:nth-of-type(4) > td {
    border-bottom: none;
  }
`;

export default ContractInfoTable;
