import { FC, memo } from 'react';
import { Form } from 'semantic-ui-react';

type Props = {
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  placeholder?: string,
  textArea?: boolean
  errorContent: string,
  error: boolean
  password?: boolean
}

const DefaultFormInput:FC<Props> = memo(({
  placeholder = '', setValue, value, textArea = false, error, errorContent, password = false,
}) => (
  textArea ? (
    <Form.TextArea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      rows={20}
      error={error ? errorContent : undefined}
    />
  )
    : (
      <Form.Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        error={error ? errorContent : undefined}
        type={password ? 'password' : 'input'}
      />
    )
));
export default DefaultFormInput;
