import { FC } from 'react';
import PasswordResetForm from '../../Organisms/Form/PasswordReset';
import AuthHeader from '../../Organisms/header/AuthHeader';
import IndexLayout from '../../Organisms/IndexLayout';
import Footer from '../../Atoms/Footer';

const PasswordReset:FC = () => (
  <>
    <AuthHeader />
    <IndexLayout odd height={600}>
      <PasswordResetForm />
    </IndexLayout>
    <Footer odd minHeihght="70px" />
  </>
);

export default PasswordReset;
