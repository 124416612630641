import { FC } from 'react';
import { css } from '@emotion/react/macro';
import {
  Container, Header, Message, Segment,
} from 'semantic-ui-react';
import { useHistory } from 'react-router';
import SegmentTitle from '../../Atoms/setting/SegmentTitle';
import SettingText from '../../Atoms/setting/SettingText';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { URL } from '../../../util';

type Props = {
  setActive: React.Dispatch<React.SetStateAction<string>>
}
const ServiceStopDone:FC<Props> = ({ setActive }) => {
  const history = useHistory();
  const onClickHandle = () => {
    setActive('dashboard');
    history.push(URL.dashboard);
  };

  return (
    <Container css={segmntMargin}>
      <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
        <SegmentTitle rightText="">
          利用解除完了
        </SegmentTitle>
        <SettingText center>
          <div css={pCenter}>
            <Header size="large" textAlign="center" color="blue">GrantDriveの契約更新を完了しました。</Header>
            <br />
            <Message
              icon="info circle"
              list={[
                '利用を停止する際にはお客様テナントにおけるBoxの管理コンソールから、カスタムアプリ連携の解除をお願いいたします。',
                '詳細な手順については、操作手順資料を参照ください。',
              ]}
              info
              css={{ width: '70% !important', margin: '45px auto 0 !important' }}
            />
          </div>
        </SettingText>
        <div css={buttonBottom}>
          <DefaultButton
            text="TOPへ戻る"
            marginTp="50px"
            onClinckFunc={onClickHandle}
            width="30%"
          />
        </div>
      </Segment>
    </Container>
  );
};

export default ServiceStopDone;

const segmntMargin = css({
  marginTop: '15px',
  minHeight: 'calc(100vh - 200px)',
});

const segmentWidth = css({
  maxWidth: '900px',
});

const buttonBottom = css({
  marginBottom: '100px',
});
const pCenter = css`
  p {
    text-align: center;
  }
`;
