import { FC, memo, useContext } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import {
  Visibility, Menu, Image, Popup, Icon,
} from 'semantic-ui-react';

import logo from '../../../assets/GrantDrive_logo.svg';
import { Context } from '../../../context/ContextProvider';
import { CognitoLogout } from '../../../Repository/Cognito';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}
const PortalHeader:FC<Props> = memo(({ active, setActive }) => {
  const { userName } = useContext(Context);
  const history = useHistory();

  const onClickHandle = (key: string, e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setActive(key);
    history.push(`/portal/${key}`);
  };
  const logout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (userName) {
      void CognitoLogout(userName, history);
    }
  };

  return (
    <Visibility once css={headerLayout}>
      <Menu pointing secondary size="large" color="blue">
        <Menu.Item as="button">
          <Image src={logo} css={logoPosition} />
        </Menu.Item>
        <Menu.Item
          active={active === 'dashboard'}
          link
          as="a"
          onClick={(e) => onClickHandle('dashboard', e)}
        >
          ダッシュボード
        </Menu.Item>
        <Menu.Item
          active={active === 'settings'}
          as="a"
          link
          onClick={(e) => onClickHandle('settings', e)}
        >
          設定
        </Menu.Item>
        <Menu.Item
          active={active === 'usage'}
          as="a"
          link
          href="https://support.grantdrive.jp/hc/ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          サポートサイト
          <Icon name="external alternate" size="small" color="blue" css={menuIcon} />
        </Menu.Item>
        {
          userName && (
            <Menu.Item position="right" css={loginPosition} as="a">
              <Popup
                content={(<Menu.Item as="a" css={pointerStyle} onClick={(e) => logout(e)}>ログアウト</Menu.Item>)}
                on="click"
                pinned
                position="bottom right"
                trigger={<div css={user}>{userName}</div>}
              />
            </Menu.Item>
          )

        }
      </Menu>
    </Visibility>
  );
});

const headerLayout = css({
  backgroundColor: '#fff',
});
const loginPosition = css({
  right: '20px',
});
const logoPosition = css({
  top: '3px',
  marginRight: '15px',
});
const pointerStyle = css({
  cursor: 'pointer',
});

const menuIcon = css`
  margin-left: 5px !important;
`;

const user = css`
::after{
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: -2px;
  content: "";
  border: 4px solid transparent;
  border-top-color: currentcolor;
  margin-right: 10px;
  position: relative;
  left: 3px;
}
`;
export default PortalHeader;
