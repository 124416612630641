import { FC, ReactNode } from 'react';
import { css } from '@emotion/react/macro';
import { Grid } from 'semantic-ui-react';

type Props ={
  children: ReactNode
  center? : boolean
  marginTp?: string
}
const SettingText:FC<Props> = ({ children, center = false, marginTp = '20px' }) => {
  const textStyle = css({
    margin: marginTp,
    color: '#898989',
    fontsize: '14px',
  });

  return (
    <Grid.Column css={textStyle} textAlign={center ? 'center' : undefined}>
      {children}
    </Grid.Column>
  );
};

export default SettingText;
