import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';
import { Grid } from 'semantic-ui-react';

type Props ={
  children: ReactNode,
  rightText?: string | React.ReactNode
}

const SegmentHeader: FC<Props> = ({ children, rightText }) => (
  <Grid>
    <Grid.Row css={rowMargin}>
      {
        rightText ? (
          <>
            <Grid.Column width="4" css={thStyleLeft}>
              {children}
            </Grid.Column>
            <Grid.Column width="12" textAlign="right" css={thStyleLeft}>
              {
              typeof (rightText) === 'string'
              && (<span css={headerRight}>{rightText}</span>)
            }
              {
              typeof (rightText) !== 'string'
              && (rightText)
            }
            </Grid.Column>
          </>
        )
          : (
            <Grid.Column width="16" css={thStyleLeft}>
              {children}
            </Grid.Column>
          )
      }
    </Grid.Row>
  </Grid>
);

const headerRight = css({
  // marginLeft: '150px',
  color: 'rgb(156, 166, 177)',
  top: '0.5%',
  fontSize: '12px',
});

const thStyleLeft = css`
cursor: auto;
text-align: inherit;
color: rgba(0, 0, 0, 0.87);
padding: 0.78571429em;
vertical-align: inherit;
font-weight: bold;
font-size: 16px;
text-transform: none;
border-bottom: 1px solid rgba(34, 36, 38, 0.1);
`;

const rowMargin = css`
  padding-top: 0px !important;
`;

export default SegmentHeader;
