export const URL = {
  index: '/',
  service: '/service',
  contact: '/contact',
  contactDone: '/contact-done',
  login: '/login',
  passwordReset: '/portal/password/reset',
  passwordChange: '/portal/password/change',
  passwordChangeDone: '/portal/password/done',
  dashboard: '/portal/dashboard',
  settings: '/portal/settings',
  addAccount: '/portal/settings/add-account',
  credit: '/portal/credit',
  creditChange: '/portal/credit-change',
  creditDone: '/portal/credit-done',
};
