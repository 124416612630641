import { FC, useEffect } from 'react';
import { css } from '@emotion/react/macro';
import { Grid } from 'semantic-ui-react';
import ServiceMain from '../../Organisms/entrance/ServiceMain';
import Footer from '../../Atoms/Footer';
import PageTitle from '../../Organisms/Layout/PageTitle';
import { useTopScroll } from '../../../hooks/useTopScroll';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const Service:FC<Props> = ({ setActive }) => {
  useTopScroll();
  useEffect(() => {
    setActive('service');
    document.title = 'GrantDrive | サービス概要';
  }, [setActive]);

  return (
    <>
      <PageTitle odd height={80} center>
        <Grid.Row textAlign="center" verticalAlign="middle" css={serviceTitile}>
          サービス概要
        </Grid.Row>
      </PageTitle>
      <ServiceMain />
      <Footer minHeihght="50px" />
    </>
  );
};

const serviceTitile = css({
  fontSize: '20px',
});

export default Service;
