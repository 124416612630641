import { css } from '@emotion/react/macro';

export const tableStyle = css`
  font-size: 1em;
  width: 100%;
  background: #FFFFFF;
  margin: 1em 0em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  display: table;
  thead {
    box-shadow: none;
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  thead > tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    transition: background 0.1s ease, color 0.1s ease;
  }
  th {
    cursor: auto;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.92857143em 0.78571429em;
    font-weight: bold;
    text-transform: none;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  th:nth-of-type(1) {
    width: 31.25%;
    text-align: center;
    border-radius: 0.28571429rem 0em 0em 0em;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
  }
  th:nth-of-type(2) {
  text-align: center;
  }
  tbody {
  }
  tbody > tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    transition: background 0.1s ease, color 0.1s ease;
  }
  td {
    padding: .78571429em;
  }
  td:nth-of-type(1) {
    width: 31.25%;
    text-align: center;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
  }
  td:nth-of-type(2) {
    text-align: right;
  }
`;

export const tableStyle2 = css`
font-size: 1em;
width: 100%;
background: #FFFFFF;
margin: 1em 0em;
border: 1px solid rgba(34, 36, 38, 0.15);
box-shadow: none;
border-radius: 0.28571429rem;
text-align: left;
color: rgba(0, 0, 0, 0.87);
border-collapse: separate;
border-spacing: 0px;
display: table;
thead {
  box-shadow: none;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}
thead > tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  transition: background 0.1s ease, color 0.1s ease;
}
th {
  cursor: auto;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.92857143em 0.78571429em;
  font-weight: bold;
  text-transform: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
th:nth-of-type(1) {
  width: 25%;
  text-align: center;
  border-radius: 0.28571429rem 0em 0em 0em;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}
th:nth-of-type(2) {
  width: 31.25%;
  text-align: center;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}
th:nth-of-type(3) {
  text-align: center;
}
tbody {
}
tbody > tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  transition: background 0.1s ease, color 0.1s ease;
}
td {
  padding: .78571429em;
}
td:nth-of-type(1) {
  text-align: center;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
td:nth-of-type(2) {
  text-align: center;
  border-right: 1px solid rgba(34, 36, 38, 0.1);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
td:nth-of-type(3) {
  text-align: right;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

`;
