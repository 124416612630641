/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  useContext, useState, FC, ReactNode,
} from 'react';
import { css } from '@emotion/react/macro';
import {
  Container, Form, Icon, Segment,
} from 'semantic-ui-react';
import { Context } from '../../../context/ContextProvider';
import { useGetTenantInfo } from '../../../hooks/useGetTenantInfo';
import { TenantRepository } from '../../../Repository/TenantRepository';
import DefaultButton from '../../Atoms/button/DefaultButton';
import Loading from '../../Atoms/Loading';
import SettingText from '../../Atoms/setting/SettingText';
import SettingForm from '../../Molecules/SettingForm';
import SettingToggle from '../../Molecules/SettingToggle';
import { putTenatValidType, initTenantInfoErr } from '../../../util/validate/PutTenantInfoValidation';
import ComfirmdDalog from '../../Atoms/ComfirmdDalog ';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';
import ErrorMassage from '../../Atoms/ErrorMessage';
import WhiteBlackList from '../../Molecules/WhiteBlackList';

const PasswordSetting:FC = () => {
  const { idtoken, tenantAlias } = useContext(Context);
  const [loading, setIsloading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string | ReactNode>('');
  const [err, setErr] = useState<putTenatValidType>(initTenantInfoErr);
  const {
    sharedlinkAutopasswordEnable,
    sharedlinkPasswordLength,
    adminMailAddress,
    noticeForAdminEnable,
    noticeForOwnerEnable,
    noticeMailBody,
    setNoticeMailBody,
    slackWebhookEnable,
    slackWebhookUrl,
    noticeMailTitle,
    setNoticeMailTitle,
    setSharedlinkAutopasswordEnable,
    setSharedlinkPasswordLength,
    setAdminMailAddress,
    setNoticeForAdminEnable,
    setNoticeForOwnerEnable,
    setSlackWebhookEnable,
    setSlackWebhookUrl,
    sharedlinkBlackWhite,
    setSharedlinkBlackWhite,
    sharedlinkBlackList,
    setSharedlinkBlackList,
    sharedlinkBlackIdList,
    setSharedlinkBlackIdList,
    sharedlinkWhiteList,
    setSharedlinkWhiteList,
    sharedlinkWhiteIdList,
    setSharedlinkWhiteIdList,
    getError,
  } = useGetTenantInfo(setIsloading, tenantAlias, idtoken);

  const onClickHandle = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setErr(initTenantInfoErr);
    setDisable(true);

    const BlackList = sharedlinkBlackList.length !== 0 ? sharedlinkBlackList.join() : '';
    const BlackIdList = sharedlinkBlackIdList.length !== 0 ? sharedlinkBlackIdList.join() : '';
    const WhiteList = sharedlinkWhiteList.length !== 0 ? sharedlinkWhiteList.join() : '';
    const WhiteIdList = sharedlinkWhiteIdList.length !== 0 ? sharedlinkWhiteIdList.join() : '';

    void TenantRepository(tenantAlias, idtoken).post({
      sharedlinkAutopasswordEnable,
      sharedlinkPasswordLength,
      adminMailAddress,
      noticeForAdminEnable,
      noticeForOwnerEnable,
      noticeMailBody,
      slackWebhookEnable,
      slackWebhookUrl,
      noticeMailTitle,
      sharedlinkBlackWhite,
      sharedlinkBlackList: BlackList,
      sharedlinkBlackIdList: BlackIdList,
      sharedlinkWhiteList: WhiteList,
      sharedlinkWhiteIdList: WhiteIdList,
    }, setErr, setContent).then((result) => {
      if (result) {
        setContent('サービス設定を変更しました。');
      }
      setOpen(true);
      setDisable(false);
    })
      .catch(() => {
        setDisable(false);
      });
  };

  return (
    <>
      <Container css={segmntMargin}>
        <ComfirmdDalog
          buttonText="OK"
          title="サービス設定"
          content={content}
          open={open}
          onClickFunc={() => setOpen(false)}
        />
        <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
          <SegmentHeader rightText="共有リンク自動パスワード付与サービスの設定を行います">
            サービス設定
          </SegmentHeader>
          {
            !getError && loading && <Loading />
          }
          {
            getError && <ErrorMassage errMessage={getError} />
          }
          {
             !getError && !loading && (
             <Form css={pstyle}>
               <div style={{ padding: '15px' }}>
                 <SettingToggle
                   title="パスワード自動付与サービスの有効化"
                   value={sharedlinkAutopasswordEnable}
                   setValue={setSharedlinkAutopasswordEnable}
                   togglePosition={togglePosition}
                 >
                   <p>
                     共有リンク(オープンリンク)の作成を検知時に、自動でパスワードを付与します
                     <br />
                     一時的にパスワード自動付与の動作を止めたい場合などはOFFにしてください
                   </p>
                 </SettingToggle>
                 <SettingForm
                   title="パスワードの桁数"
                   value={sharedlinkPasswordLength}
                   setValue={setSharedlinkPasswordLength}
                   placeholder="15"
                   error={err.sharedlinkPasswordLength.errorFlag}
                   errorContent={err.sharedlinkPasswordLength.message}
                 >
                   <p>付与するパスワードの桁数を入力してください</p>
                   <p css={{ color: '#224ea8c7' }}>
                     <Icon name="exclamation circle" />
                     パスワードの桁数は、8〜16桁で指定してください
                   </p>
                 </SettingForm>

                 <WhiteBlackList
                   title="除外・対象フォルダ"
                   sharedlinkBlackWhite={sharedlinkBlackWhite}
                   setSharedlinkBlackWhite={setSharedlinkBlackWhite}
                   tagsBlackList={sharedlinkBlackList}
                   setTagsBlackList={setSharedlinkBlackList}
                   errBlackList={err.sharedlinkBlackList.errorFlag}
                   errBlackListContent={err.sharedlinkBlackList.message}
                   tagsBlackIdList={sharedlinkBlackIdList}
                   setTagsBlackIdList={setSharedlinkBlackIdList}
                   errBlackIdList={err.sharedlinkBlackIdList.errorFlag}
                   errBlackIdListContent={err.sharedlinkBlackIdList.message}
                   tagsWhiteList={sharedlinkWhiteList}
                   setTagsWhiteList={setSharedlinkWhiteList}
                   errWhiteList={err.sharedlinkWhiteList.errorFlag}
                   errWhiteListContent={err.sharedlinkWhiteList.message}
                   tagsWhiteIdList={sharedlinkWhiteIdList}
                   setTagsWhiteIdList={setSharedlinkWhiteIdList}
                   errWhiteIdList={err.sharedlinkWhiteIdList.errorFlag}
                   errWhiteIdListContent={err.sharedlinkWhiteIdList.message}
                 />

                 <SettingForm
                   title="管理者メールアドレス"
                   value={adminMailAddress}
                   setValue={setAdminMailAddress}
                   placeholder="Emailアドレスを入力"
                   error={err.adminMailAddress.errorFlag}
                   errorContent={err.adminMailAddress.message}
                 >
                   <p>自動パスワード付与動作の全ての発行通知を受けとる管理者のメールアドレスを指定します</p>
                 </SettingForm>
                 <SettingToggle
                   title="管理者への通知"
                   value={noticeForAdminEnable}
                   setValue={setNoticeForAdminEnable}
                   togglePosition={toggleRight}
                 >
                   <p>自動パスワード付与の際に、リンク作成者に加えて管理者メールアドレス宛にも通知メールを送付します</p>
                 </SettingToggle>
                 <SettingToggle
                   title="所有者への通知"
                   value={noticeForOwnerEnable}
                   setValue={setNoticeForOwnerEnable}
                   togglePosition={toggleRight}
                 >
                   <p>自動パスワード付与の際に、リンク作成者に加えてファイルおよびフォルダの所有者宛にも通知メールを送付します</p>
                   <p css={{ color: '#224ea8c7' }}>
                     <Icon name="exclamation circle" />
                     所有者への通知をONにした場合でも、共同所有者への通知メールは送付されません
                   </p>
                 </SettingToggle>
                 <SettingToggle
                   title="Slack通知"
                   value={slackWebhookEnable}
                   setValue={setSlackWebhookEnable}
                   togglePosition={toggleRight}
                 >
                   <p>
                     パスワード付与時に動作ログをSlackに通知します
                     <br />
                     SlackのIncoming Webhookを利用し、
                     単一のSlackチャンネルに動作結果を通知します
                   </p>
                   <p css={{ color: '#224ea8c7' }}>
                     <Icon name="exclamation circle" />
                     利用テナント側でSlackのIncoming Webhook設定が必要です
                   </p>
                 </SettingToggle>
                 <SettingForm
                   title="Slack URL"
                   value={slackWebhookUrl}
                   setValue={setSlackWebhookUrl}
                   placeholder=""
                   error={err.slackWebhookUrl.errorFlag}
                   errorContent={err.slackWebhookUrl.message}
                 >
                   <p>通知するSlackURLを指定します</p>
                 </SettingForm>
                 <SettingForm
                   title="メールタイトル"
                   value={noticeMailTitle}
                   setValue={setNoticeMailTitle}
                   placeholder=""
                   error={err.noticeMailTitle.errorFlag}
                   errorContent={err.noticeMailTitle.message}
                 >
                   <p>パスワード検知メールのタイトルを指定します</p>
                 </SettingForm>
                 <Form.Group>
                   <Form.Field width="16">
                     <h5>通知メッセージテンプレート</h5>
                     <SettingText marginTp="10px">
                       <p>
                         通知メッセージのテンプレートを指定します
                       </p>
                       <p>
                         オブジェクトID: %object_id% &nbsp; オブジェクト名: %file_name%&nbsp;
                         共有リンク: %sharedlink_url% &nbsp; 共有リンクパスワード：%password%

                       </p>
                     </SettingText>
                     <Form.TextArea
                       rows="15"
                       style={{ marginBottom: '20px' }}
                       value={noticeMailBody}
                       onChange={(e) => setNoticeMailBody(e.target.value)}
                       error={err.noticeMailBody.errorFlag && err.noticeMailBody.message}
                     />
                   </Form.Field>
                 </Form.Group>
                 <Form.Group>
                   <Form.Field width="16">
                     <DefaultButton
                       text="変更する"
                       onClinckFunc={(e) => onClickHandle(e)}
                       disabled={disable}
                       marginTp="0"
                       width="30%"
                     />
                   </Form.Field>
                 </Form.Group>
               </div>
             </Form>
             )
          }
        </Segment>
      </Container>
    </>
  );
};

export default PasswordSetting;

const segmentWidth = css({
  maxWidth: '900px',
});

const togglePosition = css({
  marginTop: '15px',
  float: 'right',
});

const toggleRight = css({
  float: 'right',
});

const segmntMargin = css({
  marginTop: '30px',
  height: 'calc(100vh -300px)',
  minHeight: '780px',
});
const pstyle = css`
  p {
    font-size: 12px;
  }
`;
