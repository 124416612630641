/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable default-case */

export type putTenatValidType = {
  'sharedlinkAutopasswordEnable': {
    message: string,
    errorFlag: boolean
  },
  'sharedlinkPasswordLength': {
    message: string,
    errorFlag: boolean
  },
  'adminMailAddress': {
    message: string,
    errorFlag: boolean
  },
  'noticeForAdminEnable': {
    message: string,
    errorFlag: boolean
  },
  'noticeForOwnerEnable': {
    message: string,
    errorFlag: boolean
  },
  'slackWebhookEnable': {
    message: string,
    errorFlag: boolean
  },
  'slackWebhookUrl': {
    message: string,
    errorFlag: boolean
  },
  'noticeMailBody': {
    message: string,
    errorFlag: boolean
  },
  'noticeMailTitle': {
    message: string,
    errorFlag: boolean
  }
  'sharedlinkBlackList': {
    message: string,
    errorFlag: boolean
  },
  'sharedlinkBlackIdList': {
    message: string,
    errorFlag: boolean
  },
  'sharedlinkWhiteList': {
    message: string,
    errorFlag: boolean
  },
  'sharedlinkWhiteIdList': {
    message: string,
    errorFlag: boolean
  },
}
export const initTenantInfoErr:putTenatValidType = {
  sharedlinkAutopasswordEnable: {
    message: '',
    errorFlag: false,
  },
  sharedlinkPasswordLength: {
    message: '',
    errorFlag: false,
  },
  adminMailAddress: {
    message: '',
    errorFlag: false,
  },
  noticeForAdminEnable: {
    message: '',
    errorFlag: false,
  },
  noticeForOwnerEnable: {
    message: '',
    errorFlag: false,
  },
  slackWebhookEnable: {
    message: '',
    errorFlag: false,
  },
  slackWebhookUrl: {
    message: '',
    errorFlag: false,
  },
  noticeMailBody: {
    message: '',
    errorFlag: false,
  },
  noticeMailTitle: {
    message: '',
    errorFlag: false,
  },
  sharedlinkBlackList: {
    message: '',
    errorFlag: false,
  },
  sharedlinkBlackIdList: {
    message: '',
    errorFlag: false,
  },
  sharedlinkWhiteList: {
    message: '',
    errorFlag: false,
  },
  sharedlinkWhiteIdList: {
    message: '',
    errorFlag: false,
  },
};

export class PutTenantValidation {
  private putTenantErr:putTenatValidType = {
    sharedlinkAutopasswordEnable: {
      message: '',
      errorFlag: false,
    },
    sharedlinkPasswordLength: {
      message: '',
      errorFlag: false,
    },
    adminMailAddress: {
      message: '',
      errorFlag: false,
    },
    noticeForAdminEnable: {
      message: '',
      errorFlag: false,
    },
    noticeForOwnerEnable: {
      message: '',
      errorFlag: false,
    },
    slackWebhookEnable: {
      message: '',
      errorFlag: false,
    },
    slackWebhookUrl: {
      message: '',
      errorFlag: false,
    },
    noticeMailBody: {
      message: '',
      errorFlag: false,
    },
    noticeMailTitle: {
      message: '',
      errorFlag: false,
    },
    sharedlinkBlackList: {
      message: '',
      errorFlag: false,
    },
    sharedlinkBlackIdList: {
      message: '',
      errorFlag: false,
    },
    sharedlinkWhiteList: {
      message: '',
      errorFlag: false,
    },
    sharedlinkWhiteIdList: {
      message: '',
      errorFlag: false,
    },
  }

  setErrorMassage(messages: []):void {
    messages.forEach((message:any) => {
      switch (message.loc[0]) {
        case 'sharedlink_autopassword_enable':
          this.putTenantErr.sharedlinkAutopasswordEnable.message = message.message;
          this.putTenantErr.sharedlinkAutopasswordEnable.errorFlag = true;
          break;
        case 'sharedlink_password_length':
          this.putTenantErr.sharedlinkPasswordLength.message = message.message;
          this.putTenantErr.sharedlinkPasswordLength.errorFlag = true;
          break;
        case 'admin_mail_address':
          this.putTenantErr.adminMailAddress.message = message.message;
          this.putTenantErr.adminMailAddress.errorFlag = true;
          break;
        case 'notice_for_admin_enable':
          this.putTenantErr.noticeForAdminEnable.message = message.message;
          this.putTenantErr.noticeForAdminEnable.errorFlag = true;
          break;
        case 'notice_for_owner_enable':
          this.putTenantErr.noticeForOwnerEnable.message = message.message;
          this.putTenantErr.noticeForOwnerEnable.errorFlag = true;
          break;
        case 'slack_webhook_enable':
          this.putTenantErr.slackWebhookEnable.message = message.message;
          this.putTenantErr.slackWebhookEnable.errorFlag = true;
          break;
        case 'slack_webhook_url':
          this.putTenantErr.slackWebhookUrl.message = message.message;
          this.putTenantErr.slackWebhookUrl.errorFlag = true;
          break;
        case 'notice_mail_body':
          this.putTenantErr.noticeMailBody.message = message.message;
          this.putTenantErr.noticeMailBody.errorFlag = true;
          break;
        case 'notice_mail_title':
          this.putTenantErr.noticeMailTitle.message = message.message;
          this.putTenantErr.noticeMailTitle.errorFlag = true;
          break;
        case 'sharedlink_black_list':
          this.putTenantErr.sharedlinkBlackList.message = message.message;
          this.putTenantErr.sharedlinkBlackList.errorFlag = true;
          break;
        case 'sharedlink_black_id_list':
          this.putTenantErr.sharedlinkBlackIdList.message = message.message;
          this.putTenantErr.sharedlinkBlackIdList.errorFlag = true;
          break;
        case 'sharedlink_white_list':
          this.putTenantErr.sharedlinkWhiteList.message = message.message;
          this.putTenantErr.sharedlinkWhiteList.errorFlag = true;
          break;
        case 'sharedlink_white_id_list':
          this.putTenantErr.sharedlinkWhiteIdList.message = message.message;
          this.putTenantErr.sharedlinkWhiteIdList.errorFlag = true;
          break;
      }
    });
  }

  getObj():putTenatValidType {
    return this.putTenantErr;
  }
}
