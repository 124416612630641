import React, { memo } from 'react';
import { Form } from 'semantic-ui-react';

type Props = {
  label: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  value: string
  errorFlag?:boolean
  errorContent?: string
}
const DefaultCheckbox:React.FC<Props> = memo(({
  label, setValue, value, errorFlag = false, errorContent = '',
}) => (
  <Form.Checkbox
    radio
    label={label}
    checked={value === label}
    onChange={() => setValue(label)}
    error={errorFlag && errorContent}
  />
));

export default DefaultCheckbox;
