import { FC } from 'react';
import StripeCreditChange from '../../Organisms/dashBoard/StripeCreditChange';
import PortalHeader from '../../Organisms/header/PortalHeader';
import Footer from '../../Atoms/Footer';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}
const CreditChange:FC<Props> = ({ active, setActive }) => (
  <>
    <PortalHeader active={active} setActive={setActive} />
    <StripeCreditChange />
    <Footer odd />
  </>
);

export default CreditChange;
