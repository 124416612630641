/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { Form } from 'semantic-ui-react';
import DefaultInput from '../Atoms/Form/DefaultInput';

type Props = {
  labelText: string,
  placeholder: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  required?: boolean
  textArea?: boolean
  error: boolean
  errorContent: string
  password?: boolean
}
const DefaultFormInput: FC<Props> = memo(({
  labelText, setValue, value,
  required = false, placeholder, textArea = false, error, errorContent, password = false,
}) => (
  <Form.Field css={formMargin}>
    <label>
      {labelText}
      {required && (<span css={requireStyle}>※</span>)}
    </label>
    <DefaultInput
      value={value}
      setValue={setValue}
      placeholder={placeholder}
      textArea={textArea}
      error={error}
      errorContent={errorContent}
      password={password}

    />
  </Form.Field>
));

const requireStyle = css({
  color: 'red',
  marginLeft: '5px',
});

const formMargin = css({
  margin: '0 0 2em !important',
});

export default DefaultFormInput;
