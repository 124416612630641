import { css } from '@emotion/react';
import { FC } from 'react';

type Props = {
  eventLog: {
    createtAt: string,
    fileName: string,
    createdUser: string
  }[]
}

const EventStateTable:FC<Props> = ({ eventLog }) => (
  <table css={TableStyle}>
    <thead>
      <tr>
        <th>日時</th>
        <th>ファイル名</th>
        <th>作成者</th>
      </tr>
    </thead>
    <tbody>
      {
        eventLog.map((colum) => (
          <tr key={colum.createtAt}>
            <td>{colum.createtAt}</td>
            <td>{colum.fileName}</td>
            <td>{colum.createdUser}</td>
          </tr>
        ))
      }
    </tbody>
  </table>
);

const TableStyle = css`
width:100%;
background: #FFFFFF;
margin: 2em 0;
border: 1px solid rgba(34, 36, 38, 0.15);
box-shadow: none;
border-radius: 0.5rem;
text-align: left;
vertical-align: middle;
color: rgba(0, 0, 0, 0.87);
border-collapse: separate;
border-spacing: 0;
font-family: "Noto Sans JP";

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

thead > tr {
  font-size: 10px;
  display: table-row;
  height: 3rem;
  vertical-align: inherit;
  border-color: inherit;
}

thead > tr > th {
  background-color: #224EA8;
  cursor: auto;
  color: white;
  padding: 0.7em 1.2em;
  vertical-align: inherit;
  font-size: 11px;
  text-transform: none;
  border-bottom: 1px solid #224EA8;
  border-left: none;
}
tbody {
  text-align: inherit;
  vertical-align: inherit;
}
tbody > tr {
  display: table-row;
  height: 3rem;
  vertical-align: inherit;
  border-color: inherit;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}
tbody > tr > td {
  padding: 0.7em 1.2em;
  text-align: inherit;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  font-size: 12px;
}
th:nth-of-type(1) {
  width: 20%;
}
th:nth-of-type(2) {
  width: 35%;
}
th:nth-of-type(3) {
width: 35%;
}`;

export default EventStateTable;
