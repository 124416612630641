import {
  useState, useContext, memo, FC, useEffect,
} from 'react';
import { css } from '@emotion/react';
import { Grid, Form, Segment } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';

import { Context } from '../../../context/ContextProvider';
import { CognitoLogin } from '../../../Repository/Cognito';
import DefaultButton from '../../Atoms/button/DefaultButton';
import AuthForm from '../../Atoms/Form/AuthForm';
import AuthText from '../../Atoms/auth/AuthText';
import AuthTitle from '../../Atoms/auth/AuthTitle';
import ErrorMassage from '../../Atoms/ErrorMessage';
import Loading from '../../Atoms/Loading';
import { URL } from '../../../util';

const LoginForm: FC = memo(() => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState<string | undefined>('');
  const [isloading, setIsLoading] = useState(false);
  const {
    setIdToken, setIsLogin, setAccessToken, isLogin, setUserName, setTenantAlias,
  } = useContext(Context);
  const history = useHistory();
  const loginHandle = () => {
    setIsLoading(true);
    CognitoLogin({
      email,
      password,
      setAccessToken,
      setIdToken,
      setErr,
      setIsLogin,
      history,
      setUserName,
      userName: email,
      setTenantAlias,
    });

    if (isLogin === false) {
      setEmail('');
      setPassword('');
    }
    setIsLoading(false);
  };
  useEffect(() => {
    document.title = 'GrantDrive | お客様ポータルログイン';
  }, []);

  return (
    <Grid.Column textAlign="justified">
      <Form css={segmentPosition} size="mini">
        <Segment size="large">
          <AuthTitle title="お客様ポータルログイン" />
          {err
            && (
            <ErrorMassage errMessage={err} />
            )}
          <AuthForm
            label="Email"
            value={email}
            setValue={setEmail}
          />
          <AuthForm
            label="パスワード"
            value={password}
            setValue={setPassword}
            password
          />
          { isloading && <Loading />}
          {
            !isloading && (
            <DefaultButton
              text="ログイン"
              marginTp="50px"
              onClinckFunc={loginHandle}
              disabled={isloading}
            />
            )
          }
          <Link to={URL.passwordReset} css={aStyle}>
            <AuthText marginBt="50px" marginTp="10px" border>
              パスワードを忘れた方はこちら
            </AuthText>
          </Link>
        </Segment>
      </Form>
    </Grid.Column>
  );
});

const segmentPosition = css({
  marginTop: '50px',
});
const aStyle = css({
  color: 'black',
});

export default LoginForm;
