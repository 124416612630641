/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable default-case */

export type _contactType = {
  contactType: string,
  name: string,
  kanaName: string,
  company: string,
  mail: string,
  phoneNumber: string,
  boxUserCount: string,
  boxId: string,
  question: string,
}
export type contactValidType = {
  'contactType': {
    message: string,
    errorFlag: boolean
  },
  'name': {
    message: string,
    errorFlag: boolean
  },
  'kanaName': {
    message: string,
    errorFlag: boolean
  },
  'company': {
    message: string,
    errorFlag: boolean
  },
  'mail': {
    message: string,
    errorFlag: boolean
  },
  'phoneNumber': {
    message: string,
    errorFlag: boolean
  },
  'boxUserCount': {
    message: string,
    errorFlag: boolean
  },
  'boxId': {
    message: string,
    errorFlag: boolean
  },
  'question': {
    message: string,
    errorFlag: boolean
  },

}
export const initContactErr: contactValidType = {
  contactType: {
    message: '',
    errorFlag: false,
  },
  name: {
    message: '',
    errorFlag: false,
  },
  kanaName: {
    message: '',
    errorFlag: false,
  },
  company: {
    message: '',
    errorFlag: false,
  },
  mail: {
    message: '',
    errorFlag: false,
  },
  phoneNumber: {
    message: '',
    errorFlag: false,
  },
  boxUserCount: {
    message: '',
    errorFlag: false,
  },
  boxId: {
    message: '',
    errorFlag: false,
  },
  question: {
    message: '',
    errorFlag: false,
  },
};

export class ContactValidation {
  private contactErr: contactValidType ={
    contactType: {
      message: '',
      errorFlag: false,
    },
    name: {
      message: '',
      errorFlag: false,
    },
    kanaName: {
      message: '',
      errorFlag: false,
    },
    company: {
      message: '',
      errorFlag: false,
    },
    mail: {
      message: '',
      errorFlag: false,
    },
    phoneNumber: {
      message: '',
      errorFlag: false,
    },
    boxUserCount: {
      message: '',
      errorFlag: false,
    },
    boxId: {
      message: '',
      errorFlag: false,
    },
    question: {
      message: '',
      errorFlag: false,
    },
  };

  setErrorMassage(messages: []):void {
    messages.forEach((message:any) => {
      switch (message.loc[0]) {
        case 'inquiry_type':
          this.contactErr.contactType.message = message.message;
          this.contactErr.contactType.errorFlag = true;
          break;
        case 'name':
          this.contactErr.name.message = message.message;
          this.contactErr.name.errorFlag = true;
          break;
        case 'name_kana':
          this.contactErr.kanaName.message = message.message;
          this.contactErr.kanaName.errorFlag = true;
          break;
        case 'email':
          this.contactErr.mail.message = message.message;
          this.contactErr.mail.errorFlag = true;
          break;
        case 'phone_number':
          this.contactErr.phoneNumber.message = message.message;
          this.contactErr.phoneNumber.errorFlag = true;
          break;
        case 'organization_name':
          this.contactErr.company.message = message.message;
          this.contactErr.company.errorFlag = true;
          break;
        case 'box_account_id_count':
          this.contactErr.boxUserCount.message = message.message;
          this.contactErr.boxUserCount.errorFlag = true;
          break;
        case 'box_enterprise_id':
          this.contactErr.boxId.message = message.message;
          this.contactErr.boxId.errorFlag = true;
          break;
        case 'question':
          this.contactErr.question.message = message.message;
          this.contactErr.question.errorFlag = true;
          break;
      }
    });
  }

  getContactObj():contactValidType {
    return this.contactErr;
  }
}
