import { memo, FC } from 'react';
import { Button, SemanticFLOATS, SemanticSIZES } from 'semantic-ui-react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import { URL } from '../../../util';

type Props = {
  text: string,
  marginTp: string,
  disabled? : boolean
  width? :string
  floated? :SemanticFLOATS | undefined
  size? : SemanticSIZES | undefined,
  index?: boolean,
}
const EnranceButton:FC<Props> = memo(({
  text, marginTp, disabled, width = '100%', floated = undefined, size = 'large', index = false,
}) => {
  const history = useHistory();
  const onClickHandle = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    history.push(URL.contact);
  };
  const buttonStyle = css({
    marginTop: marginTp,
    textAlign: 'center',
  });

  const buttonSize = css({
    width,
  });
  const entranceButtonSize = css({
    width: '398px',
    padding: '16px 20px !important',
    'font-size': '16px !important',
  });

  return (
    <div css={buttonStyle}>
      <Button
        color="orange"
        size={size || undefined}
        css={index ? entranceButtonSize : buttonSize}
        disabled={disabled}
        onClick={(e) => onClickHandle(e)}
        floated={floated || undefined}
      >
        {text}
      </Button>
    </div>
  );
});

export default EnranceButton;
