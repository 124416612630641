import { css } from '@emotion/react';
import { useState, FC, useEffect } from 'react';
import {
  Grid, Form, Segment,
} from 'semantic-ui-react';

// import DefaultButton from '../../Atoms/button/DefaultButton';
import { useHistory } from 'react-router-dom';
import AuthForm from '../../Atoms/Form/AuthForm';
import AuthText from '../../Atoms/auth/AuthText';
import AuthTitle from '../../Atoms/auth/AuthTitle';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { FogotPassword } from '../../../Repository/Cognito';

const PasswordResetForm: FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onSubmitHandle = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setLoading(true);
    e.preventDefault();
    FogotPassword({ history, email, setLoading });
  };

  useEffect(() => {
    document.title = 'GrantDrive | パスワードリセット - メール送信';
  }, []);

  return (
    <Grid.Column textAlign="justified">
      <Form css={segmentPosition} size="mini">
        <Segment size="large">
          <AuthTitle title="パスワードリセット" />
          <AuthText
            marginBt="10px"
            marginTp="20px"
          >
            パスワードを忘れた方は、下記にメールアドレスを入力してください。
            <br />
            パスワードリセット方法についてのメールが送信されます。
          </AuthText>
          <AuthForm
            label="Email"
            value={email}
            setValue={setEmail}
            marginTp="20px"
            reset
          />
          <div css={buttonMargin}>
            <DefaultButton
              text="送信"
              marginTp="40px"
              disabled={loading}
              onClinckFunc={(e) => onSubmitHandle(e)}
            />
          </div>
        </Segment>
      </Form>
    </Grid.Column>
  );
};

const segmentPosition = css({
  marginTop: '50px',
});
const buttonMargin = css({
  marginBottom: '50px',
});
export default PasswordResetForm;
