import React from 'react';
import { css } from '@emotion/react/macro';
import { Container, Grid } from 'semantic-ui-react';

type Props = {
  children: React.ReactNode,
  odd: boolean,
  height: number
  center?: boolean,
  text? : boolean,
  setting?: boolean
  calcPix? :string
}
const IndexLayout: React.FC<Props> = ({
  children, odd, center = false, height, text = false, setting = false, calcPix = '130px',
}) => {
  const containerStyle = css({
    fontSize: '30px',
    minHeight: height,
    height: `calc(100vh -  ${calcPix})`,
    backgroundColor: odd ? 'rgb(232, 241, 251)' : '#fff',
    overflowY: 'auto',
  });

  const settingcontainerStyle = css({
    height,
    backgroundColor: odd ? 'rgb(232, 241, 251)' : '#fff',
    marginTop: '15px',
  });

  return (
    <div css={setting ? settingcontainerStyle : containerStyle}>
      <Container textAlign={center ? 'center' : 'left'} text={text}>
        <Grid columns={2} textAlign="center">
          {children}
        </Grid>
      </Container>
    </div>
  );
};

export default IndexLayout;
