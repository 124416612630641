export const ValidateMessage = {
  notKana: 'カタカナで入力してください。',
  notMail: 'メールアドレスの形式が不正です。',
  phoneFormat: '電話番号の形式に誤りがあります。',
  format: '数値を入力してください。',
  empty: '必須項目です',
};

export const SettingModalError = {
  UnexpectedMassageFirst: '申し訳ありません。設定の変更に失敗しました',
  UnexpectedMassageSecond: 'しばらくしてから再度お試しください',
  inputError: '入力項目に不正があります。',
};

// jsx Elements
export const SettingModalMessage = (
  <>
    {SettingModalError.UnexpectedMassageFirst}
    <br />
    {SettingModalError.UnexpectedMassageSecond}
  </>
);
