/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { URL } from '../util';
import { createAxiosInstance } from './repository';

type StripeRepositoryType = {
  post: (
    tenantAlias: string,
    count: string,
    paymentMethod: any,
    paymentPlan: number,
    history: any,
  ) => Promise<void>;
  put: (
    tenantAlias: string,
    count: string,
    paymentMethodId: string | undefined,
    paymentPlan: number,
    history: any,
  ) => Promise<void>;
};

export const StripeRepository = (idtoken?: string): StripeRepositoryType => {
  const instace = createAxiosInstance(idtoken);
  const post = async (
    tenantAlias: string,
    count: string,
    paymentMethodId: string | undefined,
    paymentPlan: number,
    history: any,
  ) => {
    await instace
      .post(`${tenantAlias}/contract`, {
        tenant_alias: tenantAlias,
        quantity: Number(count),
        paymentMethodId,
        paymentPlan,
      })
      .then(() => {
        history.push(URL.creditDone, {
          textTitle: '利用お申し込み',
          text: '決済',
          referrer: URL.credit,
        });
      });
  };
  const put = async (
    tenantAlias: string,
    count: string,
    paymentMethodId: string | undefined,
    paymentPlan: number,
    history: any,
  ) => {
    await instace
      .put(`${tenantAlias}/contract`, {
        tenant_alias: tenantAlias,
        quantity: Number(count),
        paymentMethodId,
        paymentPlan,
      })
      .then(() => {
        history.push(URL.creditDone, {
          textTitle: '決済情報変更',
          text: '決済情報の変更',
          referrer: URL.creditChange,
        });
      });
  };

  return { post, put };
};
