import { FC } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading:FC = () => (
  <Dimmer active inverted>
    <Loader inverted content="loading" />
  </Dimmer>
);

export default Loading;
