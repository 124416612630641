import React from 'react';
import { css } from '@emotion/react/macro';
import { Container, Grid } from 'semantic-ui-react';

type Props = {
  children: React.ReactNode,
  odd: boolean,
  height: number
  center?: boolean,
  text? : boolean,
  setting?: boolean
}
const PageTitle: React.FC<Props> = ({
  children, odd, center = false, height, text = false, setting = false,
}) => {
  const containerStyle = css({
    height,
    backgroundColor: odd ? 'rgb(232, 241, 251)' : '#fff',
    overflowY: 'auto',
    alignItems: 'center',
    display: 'flex',
  });

  const settingcontainerStyle = css({
    height,
    backgroundColor: odd ? 'rgb(232, 241, 251)' : '#fff',
    marginTop: '15px',
  });

  return (
    <div css={setting ? settingcontainerStyle : containerStyle}>
      <Container textAlign={center ? 'center' : 'left'} text={text}>
        <Grid columns={2} textAlign="center">
          {children}
        </Grid>
      </Container>
    </div>
  );
};

export default PageTitle;
