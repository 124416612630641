import { FC, memo } from 'react';
import {
  Container, Card, Grid,
} from 'semantic-ui-react';
import { css } from '@emotion/react/macro';
import firstCardIllust from '../../../assets/index/HTMLillust_1.svg';
import secondCardIllust from '../../../assets/index/illust_2.svg';
import thrdCardIllust from '../../../assets/index/HTMLillust_3.svg';
import { MainSecondExpansionText } from '../../../text/MainSecondExpansionText';
import MainCard from '../../Molecules/MainCard';
import EntranceTitle from '../../Atoms/entrance/EntranceTitle';

const MainSecondExpansion:FC = memo(() => (
  <div css={containreMargin}>
    <Container style={{ width: '1024px' }}>
      <Grid textAlign="center">
        <EntranceTitle title="導入するメリット" color />
      </Grid>
      <Card.Group
        itemsPerRow={3}
        textAlign="center"
      >
        <MainCard
          image={firstCardIllust}
          title={(
              MainSecondExpansionText.first.title
            )}
          description={(
              MainSecondExpansionText.first.description
            )}
        />
        <MainCard
          image={secondCardIllust}
          title={(
              MainSecondExpansionText.second.title
            )}
          description={(
            <>
              {MainSecondExpansionText.second.description[0]}
              {MainSecondExpansionText.second.description[1]}
              <br />
              {MainSecondExpansionText.second.description[2]}
            </>
            )}
        />
        <MainCard
          therd
          image={thrdCardIllust}
          title={(
            <>
              <span css={titleScondStyle}>{MainSecondExpansionText.therd.title[0]}</span>
              <br />
              <span css={titleScondStyle && border}>
                {MainSecondExpansionText.therd.title[1]}
              </span>
            </>
            )}
          description={(
            <>
              {MainSecondExpansionText.therd.description[0]}
              {MainSecondExpansionText.therd.description[1]}
              <br />
              {MainSecondExpansionText.therd.description[2]}
            </>
            )}
        />
      </Card.Group>
    </Container>
  </div>
));

const titleScondStyle = css`
  line-height: 1.8em;
`;

const border = css`
border-bottom: 3px solid #224EA8;
padding-bottom: 5px;
`;

const containreMargin = css`
  background-color: #fff;
  margin-bottom: 20px;
  padding-bottom: 100px;
`;

export default MainSecondExpansion;
