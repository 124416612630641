import { memo, FC } from 'react';
import { css } from '@emotion/react/macro';

type Props = {
  title: string
}

const AuthTitle: FC<Props> = memo(({ title }) => (
  <div css={hPosition}>
    <h3>{title}</h3>
  </div>
));

const hPosition = css({
  textAlign: 'center',
  marginTop: '30px',
  marginBottom: '30px',
});

export default AuthTitle;
