import React, { useState, useEffect } from 'react';

import { AccountRepository } from '../Repository/AccountRepository';

type Props = {
  id: string;
  tenant: string;
  idtoken: string | undefined;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type useGetAccountUserType = {
  err: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  departmentName: string;
  setDepartmentName: React.Dispatch<React.SetStateAction<string>>;
};
export const useGetAccountUser = ({
  id,
  tenant,
  setLoading,
  idtoken,
}: Props): useGetAccountUserType => {
  const [err, setErr] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [departmentName, setDepartmentName] = useState('');

  useEffect(() => {
    void AccountRepository(tenant, idtoken)
      .getUser(id)
      .then((data) => {
        setName(data.name);
        setEmail(data.email);
        setPhoneNumber(data.phoneNumber);
        setDepartmentName(data.departmentName);
      })
      .catch(() => {
        setErr('ユーザー情報が取得できませんでした。');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, idtoken, setLoading, tenant]);

  return {
    err,
    name,
    setName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    departmentName,
    setDepartmentName,
  };
};
