/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable camelcase */
import { AddAccountValidation, AddAccountValidType } from '../util/validate/AddAccountValidation';
import {
  PutChangeUserValidation,
  putUserValidType,
} from '../util/validate/PutChangeUserValidation';
import { createAxiosInstance } from './repository';

type account = {
  name: string;
  email: string;
  phone_number: string;
  department_name: string;
  user_id: string;
};

export type accountUserType = {
  name: string;
  email: string;
  phoneNumber: string;
  departmentName: string;
};

type accountApiType = {
  account: account[];
  total_count: number;
};

type accountUserApiType = {
  name: string;
  email: string;
  phone_number: string;
  department_name: string;
};

type signUpUserApiType = {
  name: string;
  email: string;
  password: string;
  phone_number: string;
  department_name: string;
};

type putUserApiType = {
  name: string;
  password: string;
  phone_number: string;
  department_name: string;
};

type putUserType = {
  name: string;
  phoneNumber: string;
  password: string;
  departmentName: string;
};

type signUpUserType = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  department: string;
};

export type AccounstsResponce = {
  name: string;
  email: string;
  phoneNumber: string;
  departmentName: string;
  userId: string;
}[];
type ContactRepositoryType = {
  getUsers: () => Promise<AccounstsResponce>;
  getUser: (userId: string) => Promise<accountUserType>;
  SignUpUser: (
    reqData: signUpUserType,
    setErr: React.Dispatch<React.SetStateAction<AddAccountValidType>>,
  ) => Promise<number>;
  deleteUser: (id: string) => Promise<boolean>;
  put: (
    body: putUserType,
    userId: string,
    setErr: React.Dispatch<React.SetStateAction<putUserValidType>>,
  ) => Promise<boolean>;
};
export const AccountRepository = (tenantAlias: string, idToken?: string): ContactRepositoryType => {
  const instace = createAxiosInstance(idToken);

  const getUsers = async (): Promise<AccounstsResponce> => {
    const { data } = await instace.get<accountApiType>(`${tenantAlias}/account`);
    if (data.total_count === 0) {
      return [
        {
          name: '',
          email: '',
          phoneNumber: '',
          departmentName: '',
          userId: '',
        },
      ];
    }
    const responce = data.account.map((user) => ({
      name: user.name,
      email: user.email,
      phoneNumber: user.phone_number,
      departmentName: user.department_name,
      userId: user.user_id,
    }));

    return responce;
  };
  const SignUpUser = async (
    reqData: signUpUserType,
    setErr: React.Dispatch<React.SetStateAction<AddAccountValidType>>,
  ): Promise<any> => {
    const postData: signUpUserApiType = {
      name: reqData.name,
      email: reqData.email,
      phone_number: reqData.phoneNumber,
      password: reqData.password,
      department_name: reqData.department,
    };
    try {
      const data = await instace.post<signUpUserApiType>(`${tenantAlias}/account`, postData);

      return data.status;
    } catch (e) {
      if (e.response.status === 400) {
        const valid = new AddAccountValidation();
        valid.setErrorMassage(e.response.data.messages);
        setErr(valid.getObj());
      }

      return e.response.status;
    }
  };

  const deleteUser = async (id: string): Promise<boolean> => {
    try {
      await instace.delete(`${tenantAlias}/account/${id}`);

      return true;
    } catch (_) {
      return false;
    }
  };

  const getUser = async (userId: string): Promise<accountUserType> => {
    const { data } = await instace.get<accountUserApiType>(`${tenantAlias}/account/${userId}`);

    return {
      name: data.name,
      email: data.email,
      phoneNumber: data.phone_number,
      departmentName: data.department_name,
    };
  };

  const put = async (
    body: putUserType,
    userId: string,
    setErr: React.Dispatch<React.SetStateAction<putUserValidType>>,
  ): Promise<boolean> => {
    const putData: putUserApiType = {
      name: body.name,
      password: body.password,
      department_name: body.departmentName,
      phone_number: body.phoneNumber,
    };
    try {
      await instace.put(`${tenantAlias}/account/${userId}`, putData);

      return true;
    } catch (e) {
      if (e.response.status === 400) {
        const valid = new PutChangeUserValidation();
        valid.setErrorMassage(e.response.data.messages);
        setErr(valid.getObj());
      }

      return false;
    }
  };

  return {
    getUsers,
    getUser,
    SignUpUser,
    deleteUser,
    put,
  };
};
