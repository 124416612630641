/* eslint-disable react/jsx-closing-bracket-location */
import React, { createContext, useState } from 'react';

type ContextType = {
  isAccountDelete: boolean;
  setIsAccountDelete: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AccountContext = createContext({} as ContextType);

type Props = {
  children: React.ReactNode;
};

const AccountContextProvider: React.FC<Props> = ({ children }) => {
  const [isAccountDelete, setIsAccountDelete] = useState(false);

  return (
    <AccountContext.Provider
      value={{
        isAccountDelete,
        setIsAccountDelete,
      }}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
