import { FC, useEffect } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Segment } from 'semantic-ui-react';
import Loading from '../../Atoms/Loading';
import ContractInfoTable from '../../Molecules/ContractInfoTable';
import { getCntractInfo } from '../../../Repository/ContactRepository';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';

type Props = {
  contactInfo: getCntractInfo
  loading: boolean
  err: string
}

const ContractInfo:FC<Props> = ({ contactInfo, loading, err }) => {
  useEffect(() => {
    document.title = 'GrantDrive | お客様ポータル | ダッシュボード';
  }, []);

  return (
    <>
      {!err && (
      <Container css={segmntMargin}>
        <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
          <SegmentHeader rightText="GrantDriveご契約情報です">
            ご契約状況
          </SegmentHeader>
          {loading && (<Loading />)}
          {!loading && (
          <ContractInfoTable
            tenantContractEnd={contactInfo.tenantContractEnd}
            tenantContractStart={contactInfo.tenantContractStart}
            tenantContractType={contactInfo.tenantContractType}
            tenantMaximumAccount={contactInfo.tenantMaximumAccount}
            tenantStatus={contactInfo.tenantStatus}
            tenantStatusName={contactInfo.tenantStatusName}
            isStop
          />
          )}
        </Segment>
      </Container>
      )}
    </>
  );
};
const segmntMargin = css({
  marginTop: '15px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

export default ContractInfo;
