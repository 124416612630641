import { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Context } from '../../../context/ContextProvider';
import { URL } from '../../../util';
import ChangeAccountUserForm from '../../Organisms/dashBoard/ChangeAccountUserForm';
import Footer from '../../Atoms/Footer';
import PortalHeader from '../../Organisms/header/PortalHeader';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}

type ParamProps = {
  id :string,
  tenant: string,
}

const ChangeAccountInfo:FC<Props> = ({ active, setActive }) => {
  const { id, tenant } = useParams<ParamProps>();
  const { tenantAlias } = useContext(Context);
  const history = useHistory();
  useEffect(() => {
    if (tenant !== tenantAlias) {
      history.push(URL.dashboard);
    }
  }, [history, tenant, tenantAlias]);

  return (
    <>
      <PortalHeader active={active} setActive={setActive} />
      <ChangeAccountUserForm id={id} tenantAlias={tenantAlias} />
      <Footer odd />
    </>
  );
};

export default ChangeAccountInfo;
