/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-sparse-arrays */
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { userPool } from '../Repository/Cognito';
import { URL } from '../util';

type Props = {
  setIdToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTenantAlias: React.Dispatch<React.SetStateAction<string>>;
  history: any;
};

export const useGetSession = ({
  setIdToken,
  setUserName,
  setTenantAlias,
  setAccessToken,
  history,
}: Props): boolean => {
  const user = userPool.getCurrentUser();
  if (user === null) {
    return false;
  }
  try {
    user.getSession((error: null, session: CognitoUserSession) => {
      if (error) {
        history.push(URL.login);
      }
      if (session === null) {
        history.push(URL.login);

        return;
      }
      const decodeToken = session.getIdToken().decodePayload();
      setTenantAlias(decodeToken['custom:tenant']);
      setIdToken(session.getIdToken().getJwtToken());
      setAccessToken(session.getAccessToken().getJwtToken());
      setUserName(decodeToken.email);
    });
  } catch (_) {
    return false;
  }

  return true;
};
