import { FC, ReactNode } from 'react';
import { Button, Modal, SemanticCOLORS } from 'semantic-ui-react';

type Props ={
  onClickFunc: () => void
  open: boolean,
  title: string,
  content: string | ReactNode,
  buttonText: string
  color? : SemanticCOLORS
  size? :'large' | 'mini' | 'tiny' | 'small' | 'fullscreen' | undefined
}

const ComfirmdDalog: FC<Props> = ({
  onClickFunc, open, title, content, buttonText, color = 'blue', size = 'tiny',
}) => (
  <Modal open={open} size={size}>
    <Modal.Header>
      {title}
    </Modal.Header>
    <Modal.Content>
      {content}
    </Modal.Content>
    <Modal.Actions>
      <Button color={color} onClick={onClickFunc}>{buttonText}</Button>
    </Modal.Actions>
  </Modal>
);

export default ComfirmdDalog;
