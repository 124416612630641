export const serviceText1 = [
  '本サービスはSaaS形式で提供します',
  '本サービスではご利用のBoxテナントにおいて共有リンクのパスワードを必須化する機能を提供します。',
  '動作内容としては、Boxのイベント情報をAPI経由で取得し、共有リンクの作成を検知します。システムがパスワード無しの共有リンク(オープンリンク)を発見した場合、自動的にランダムなパスワードを生成して付与します',
  '付与したパスワードは共有リンク発行者、所有者、管理者にメールで配信します。(通知先はメールで変更可)',
  '生成および付与したパスワードはシステム内では保存しません。',
  'パスワード自動付与の対象外とするフォルダを設定することが可能です。除外フォルダの設定などについては管理者向けの設定画面をご提供します。',
];

export const serviceText2 = [
  '本サービスではBox-APIのエンタープライズイベントを利用して共有リンク(オープンリンク)の作成・変更を検知しています。',
  '通常はユーザーの操作から2分以内程度でイベントを検知することができますが、まれに検知までに時間がかかる場合がございます。その場合はパスワードが設定されるまで通常よりも長い時間がかかることがあることをご了承ください。',
];
