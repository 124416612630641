import { FC, ReactNode } from 'react';
import { css } from '@emotion/react/macro';
import { Card, Image } from 'semantic-ui-react';

type Props = {
  image: string,
  title: ReactNode,
  description: ReactNode,
  therd? : boolean
}

const MainCard: FC<Props> = ({
  image, title, description, therd = false,
}) => (
  <Card centered css={cardStyle} style={{ backgroundColor: '#EDF2FA', boxShadow: 'none' }}>
    <div css={imageMargin} style={{ borderRadius: '7px' }}>
      <Image
        src={image}
        css={imageStyle}
        style={{ background: '#fff' }}
        rounded
      />
    </div>
    <Card.Content css={cardContentStyle}>
      <Card.Header textAlign="center" css={cardHeaderStyle} style={{ color: '#224EA8' }}>
        {
          therd ? (<span>{title}</span>) : (<span css={border}>{title}</span>)
        }
      </Card.Header>
      <Card.Description css={descriptionStyle}>
        {description}
      </Card.Description>
    </Card.Content>
  </Card>
);

const descriptionStyle = css`
  padding:25px 30px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.6;
`;

const cardContentStyle = css`
  border-top: none !important;
`;
const imageMargin = css`
padding: 20px;
background-color: #fff;
margin: 40px 40px 20px 40px;
`;

const cardHeaderStyle = css`
  color: #224EA8;
`;

const border = css`
border-bottom: 3px solid #224EA8;
padding-bottom: 5px;
`;

const cardStyle = css`
  overflow-wrap: break-word;
  background-color: #224EA8;
`;

const imageStyle = css`
  width: 308px;
  height: 221px;
  margin-left: auto;
  margin-right: auto;
`;

export default MainCard;
