/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../context/AccountProvider';
import { AccounstsResponce, AccountRepository } from '../Repository/AccountRepository';

type useGetAccountUsersType = {
  users: AccounstsResponce;
  err: string;
};
export const useGetAccountUsers = (
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>,
  tenantAlias: string,
  idToken: string | undefined,
): useGetAccountUsersType => {
  const [err, setErr] = useState('');
  const { isAccountDelete } = useContext(AccountContext);
  const [users, SetUsers] = useState<AccounstsResponce>([
    {
      name: '',
      departmentName: '',
      email: '',
      phoneNumber: '',
      userId: '',
    },
  ]);

  useEffect(() => {
    setIsloading(true);
    void AccountRepository(tenantAlias, idToken)
      .getUsers()
      .then((data) => {
        SetUsers(data);
        setIsloading(false);
      })
      .catch((e) => {
        if (e.status) {
          if (e.response.status === 403 || e.response.status === 404) {
            setErr('');
            setIsloading(false);

            return;
          }
        }
        setErr('アカウントが取得できませんでした。しばらくしてからお試しください。');
        setIsloading(false);
      });
  }, [idToken, setIsloading, tenantAlias, isAccountDelete]);

  return { users, err };
};
