import { memo, FC } from 'react';
import { Button, SemanticSIZES, SemanticFLOATS } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';
import { useHistory } from 'react-router-dom';
import { URL } from '../../../util';

type Props = {
  text: string,
  marginTp: string,
  marginBt?: string
  onClinckFunc: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  disabled?: boolean
  width? :string,
  size? : SemanticSIZES
  floated? :SemanticFLOATS
  accountCancelButton?: boolean
}

const DefaultButton: FC<Props> = memo(({
  text, marginTp, onClinckFunc, disabled = false, width = '30%', size = 'medium', floated = undefined, marginBt = 'opx', accountCancelButton = false,
}) => {
  const history = useHistory();
  const buttonCancel = css({
    width,
    'background-color': '#eee',
    marginRight: '16px !important',
  });
  const buttonSize = css({
    width,
  });
  const buttonStyle = css({
    marginTop: marginTp,
    marginBottom: marginBt,
    textAlign: 'center',
  });

  return (
    <div css={buttonStyle}>
      {
        accountCancelButton && (
          <Button
            type="button"
            size={size}
            css={buttonCancel}
            onClick={() => history.push(`${URL.settings}?account`)}
          >
            キャンセル
          </Button>
        )
      }
      <Button
        type="submit"
        color="blue"
        size={size}
        css={buttonSize}
        floated={floated}
        onClick={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClinckFunc(e)}
        disabled={disabled}
      >
        {text}
      </Button>
    </div>
  );
});

export default DefaultButton;
