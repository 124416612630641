import { memo, FC } from 'react';

import { serviceText1, serviceText2 } from '../../../text/ServiceText';
import EnranceButton from '../../Atoms/button/EnranceButton';
import ServiceTitle from '../../Atoms/entrance/ServiceTitle';
import ServiceText from '../../Molecules/ServiceText';
import IndexLayout from '../IndexLayout';

const ServiceMain: FC = memo(() => (
  <IndexLayout height={860} text odd={false}>
    <ServiceTitle text="機能仕様" marginTp="80" />
    <ServiceText text={serviceText1} />
    <ServiceTitle text="前提事項" marginTp="40" />
    <ServiceText text={serviceText2} />
    <EnranceButton text="トライアル申し込み・お問い合わせ" marginTp="40px" />
  </IndexLayout>
));

export default ServiceMain;
