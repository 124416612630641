/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const conf = () => {
  if (process.env.REACT_APP_AWS_DEV === 'dev') {
    return {
      baseurl: process.env.REACT_APP_BASE_URL_DEV || '',
      awsRegion: process.env.REACT_APP_AWS_REGION_DEV,
      cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_DEV || '',
      awsConfiguration: {
        region: process.env.REACT_APP_AWS_REGION_DEV || '',
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_DEV || '',
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID_DEV || '',
      },
      stripeKey: process.env.REACT_APP_STRIPE_KEY_DEV || '',
    };
  }

  return {
    baseurl: process.env.REACT_APP_BASE_URL || '',
    awsRegion: process.env.REACT_APP_AWS_REGION,
    cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
    awsConfiguration: {
      region: process.env.REACT_APP_AWS_REGION || '',
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
    },
    stripeKey: process.env.REACT_APP_STRIPE_KEY || '',
  };
};

export const config = conf();
