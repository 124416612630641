import { useContext, FC, ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Context } from '../../../context/ContextProvider';
import { useGetSession } from '../../../hooks';
import { URL } from '../../../util';

type Props = {
  children: ReactNode
}
const AuthCheck:FC<Props> = ({ children }) => {
  const history = useHistory();
  const {
    isLogin, setIdToken, setUserName, setTenantAlias, setAccessToken,
  } = useContext(Context);
  const login = useGetSession({
    setIdToken, setUserName, setTenantAlias, setAccessToken, history,
  });

  return (
    isLogin || login
      ? (
        <>
          {children}
        </>
      )
      : <Redirect to={URL.login} />
  );
};

export default AuthCheck;
