import { FC, useContext, useState } from 'react';
import { css } from '@emotion/react/macro';
import {
  Container, Form, Segment,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../../context/ContextProvider';
import { useGetAccountUser } from '../../../hooks';
import DefaultFormInput from '../../Molecules/DefaultFormInput';
import Loading from '../../Atoms/Loading';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { putUserValidType, initPutUserValid } from '../../../util/validate/PutChangeUserValidation';
import { AccountRepository } from '../../../Repository/AccountRepository';
import ComfirmdDalog from '../../Atoms/ComfirmdDalog ';
import { URL } from '../../../util';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';
import PasswordPolicyMessage from '../../Atoms/PasswordPolicyMessage';

type Props = {
  id :string,
  tenantAlias: string
}

const ChangeAccountUserForm:FC<Props> = ({ id, tenantAlias }) => {
  const { idtoken } = useContext(Context);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [validErr, setValidErr] = useState<putUserValidType>(initPutUserValid);
  const [disable, setDisable] = useState(false);
  const [content, setContent] = useState('');
  const [open, setOpen] = useState(false);
  const [repasswordErr, setRepasswordErr] = useState('');

  const {
    err,
    name,
    setName,
    phoneNumber,
    setPhoneNumber,
    departmentName,
    setDepartmentName,
  } = useGetAccountUser({
    id, tenant: tenantAlias, setLoading, idtoken,
  });

  const putUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    setDisable(true);

    if (password !== repassword) {
      setRepasswordErr('パスワードが一致していません。');
      setDisable(false);
      setLoading(false);

      return;
    }

    setRepasswordErr('');
    void AccountRepository(tenantAlias, idtoken).put({
      name,
      password,
      departmentName,
      phoneNumber,
    }, id, setValidErr)
      .then((result) => {
        setLoading(false);

        if (result) {
          setContent('ユーザーの情報の変更が完了しました。');
          setOpen(true);
        } else {
          setContent('ユーザー情報の変更に失敗しました。');
          setDisable(false);
        }
      });
  };

  return (
    <>
      <ComfirmdDalog
        title="ユーザー情報変更"
        content={content}
        open={open}
        buttonText="OK"
        onClickFunc={() => {
          setOpen(false);
          history.push(`${URL.settings}?account`);
        }}
      />
      <Container css={segmntMargin} text>
        <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
          <SegmentHeader rightText="Grant Driveユーザー情報を変更します">
            <p>ユーザー情報変更</p>
          </SegmentHeader>
          {
          err && (<h1>{err}</h1>)
        }
          {
          !err && loading && <Loading />
        }
          {
          !err && !loading && (
            <Form css={buttonBottom}>
              <DefaultFormInput
                labelText="名前"
                placeholder=""
                value={name}
                setValue={setName}
                error={validErr.name.errorFlag}
                errorContent={validErr.name.message}
                required
              />
              <DefaultFormInput
                labelText="電話番号"
                placeholder=""
                value={phoneNumber}
                setValue={setPhoneNumber}
                error={validErr.phoneNumber.errorFlag}
                errorContent={validErr.phoneNumber.message}
              />
              <DefaultFormInput
                labelText="部署"
                placeholder=""
                value={departmentName}
                setValue={setDepartmentName}
                error={validErr.departmentName.errorFlag}
                errorContent={validErr.departmentName.message}
              />
              <DefaultFormInput
                labelText="パスワード"
                placeholder=""
                value={password}
                setValue={setPassword}
                error={validErr.password.errorFlag}
                errorContent={validErr.password.message}
                password
                required
              />
              <DefaultFormInput
                labelText="パスワード(再確認)"
                placeholder=""
                value={repassword}
                setValue={setRePassword}
                error={repasswordErr !== ''}
                errorContent={repasswordErr}
                password
                required
              />
              <Form.Field>
                <DefaultButton
                  text="変更する"
                  marginTp="20px"
                  width="30%"
                  onClinckFunc={(e) => putUser(e)}
                  disabled={disable}
                  accountCancelButton
                />
              </Form.Field>
            </Form>
          )
        }
          <PasswordPolicyMessage />
        </Segment>
      </Container>
    </>
  );
};

const segmntMargin = css({
  marginTop: '30px',
});

const buttonBottom = css({
  marginTop: '10px',
  marginBottom: '40px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

export default ChangeAccountUserForm;
