/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  FC, useEffect, useState, useContext,
} from 'react';
import { css } from '@emotion/react/macro';
import {
  Button, Container, Form, Grid, Label, Segment, Message,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { card, stripe } from '../../../util/stripe';
import { Context } from '../../../context/ContextProvider';
import DefaultFormInput from '../../Molecules/DefaultFormInput';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { StripeRepository } from '../../../Repository/StripeRepository';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';
import CreditDialog from '../../Atoms/CreditDialog';
import PaymentDetailsTable from '../../Molecules/PaymentDetailsTable';

const StripeCreditChange:FC = () => {
  const minUserCount = 5;
  const maxUserCount = 1000;

  const { idtoken, tenantAlias } = useContext(Context);
  const [countErr, setcountErr] = useState('');
  const [cardNumberErr, setCardNumberErr] = useState<string | undefined>('');
  const [open, isOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>((<p>undefined</p>));
  const [count, setCount] = useState('');
  const history = useHistory();
  const [disabled, isDisabled] = useState(false);

  useEffect(() => {
    card.mount('#stripe');
  }, []);
  const segmntMargin = css({
    marginTop: '30px',
  });
  const dialogOpen = () => {
    setcountErr('');
    setCardNumberErr('');
    if (count === '' || !count.match(/\d/g) || Number.isNaN(count)) {
      setcountErr('ユーザー数の入力が不正です。');

      return;
    }
    if (Number(count) < minUserCount || Number(count) > maxUserCount) {
      setcountErr(`ユーザー数は、${minUserCount}〜${maxUserCount}の間で指定してください。`);

      return;
    }

    const message = [
      'GrantDrive月額使用料の決済変更処理を実行します。',
      '下記内容をご確認いただき、よろしければ「決済」ボタンをクリックしてください。',
    ];
    setContent(([
      <Message color="blue" icon="info circle" list={message} />,
      <PaymentDetailsTable userCount={Number(count)} />,
    ]));
    isOpen(true);
  };

  const onSubmitHandle = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    isDisabled(true);
    e.preventDefault();
    await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: tenantAlias,
      },
    })
      .then((result) => {
        if (result.error) {
          setCardNumberErr(result.error.message);
          isOpen(false);
          isDisabled(false);

          return;
        }
        void StripeRepository(idtoken).put(
          tenantAlias,
          count,
          result.paymentMethod?.id,
          0,
          history,
        );
      });
  };

  const paymentInformation = [
    `ユーザー数は、${minUserCount} 〜 ${maxUserCount} ユーザーで指定できます。`,
    `${maxUserCount} ユーザーを超える場合には、Stripeクレジットカード決済はご利用できません。`,
    'お支払い方法は「請求書払い」となりますので弊社サポートまでご連絡ください。',
  ];

  return (
    <Container text css={segmntMargin}>
      <CreditDialog
        title={(
          <p style={{ textAlign: 'center' }}>
            決済情報確認
          </p>
        )}
        onClickFunc={(e) => onSubmitHandle(e)}
        open={open}
        buttonText="決済"
        content={content}
        isOpen={isOpen}
        size="small"
        disabled={disabled}
      />
      <Segment color="blue">
        <SegmentHeader>
          決済情報入力
        </SegmentHeader>
        <Form css={buttonBottom}>
          <DefaultFormInput
            value={count}
            setValue={setCount}
            labelText="ユーザー数"
            error={countErr !== ''}
            errorContent={countErr}
            placeholder=""
            required
          />
          <Message
            color="blue"
            icon="info circle"
            list={paymentInformation}
          />
          <div>
            <label css={labelStyle}>
              カード情報
              <span css={requireStyle}>※</span>
              <div id="stripe" css={creditStyle} />
            </label>
            {cardNumberErr !== ''
            && (
            <Label
              pointing="above"
              prompt
              role="alert"
              aria-atomic="true"
              css={alertLabelStyle}
            >
              {cardNumberErr}

            </Label>
            )}
          </div>
          <DefaultButton
            text="変更する"
            marginTp="20px"
            onClinckFunc={() => dialogOpen()}
          />
          <Grid.Column verticalAlign="middle" css={gridStyle}>
            <Button
              primary
              floated="right"
              css={buttonStyle}
              onClick={() => history.goBack()}
            >
              戻る
            </Button>
          </Grid.Column>
        </Form>
      </Segment>
    </Container>
  );
};

const creditStyle = css({
  marginTop: '5px',
  padding: '10px',
  border: '1px solid rgba(34, 36, 38, 0.15)',
});
const requireStyle = css({
  color: 'red',
  marginLeft: '5px',
});

const gridStyle = css({
  marginTop: '10px',
  marginBottom: '10px',
});

const buttonStyle = css({
  width: '20%',
});

const buttonBottom = css({
  marginTop: '20px',
  marginBottom: '50px',
});

const labelStyle = css`
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
`;
const alertLabelStyle = css`
  white-space: normal;
  background: #FFFFFF !important;
  border: 1px solid #E0B4B4 !important;
  color: #9F3A38 !important;
}
`;

export default StripeCreditChange;
