/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import { css } from '@emotion/react';
import { Form } from 'semantic-ui-react';
import DefaultCheckbox from '../Atoms/Form/DefaultCheckbox';

type Props = {
  labelText: string,
  checkboxText1: string
  checkboxText2: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  errorFlag: boolean
  errorContent: string
}
const DefaultFormCheckbox: React.FC<Props> = memo(({
  labelText, setValue, value, checkboxText1, checkboxText2, errorFlag, errorContent,
}) => (
  <Form.Field css={formFieldStyle}>
    <label>
      {labelText}
      <span css={requireStyle}>※</span>
    </label>
    <div css={formGroupStyle}>
      <Form.Group inline>
        <DefaultCheckbox
          label={checkboxText1}
          setValue={setValue}
          value={value}
        />
        <DefaultCheckbox
          label={checkboxText2}
          setValue={setValue}
          value={value}
          errorFlag={errorFlag}
          errorContent={errorContent}
        />
      </Form.Group>
    </div>
  </Form.Field>
));

const requireStyle = css({
  color: 'red',
  marginLeft: '5px',
});

const formGroupStyle = css({
  marginTop: '10px',
});

const formFieldStyle = css({
  margin: '0 0 2em !important',
});
export default DefaultFormCheckbox;
