import { memo, FC, ReactNode } from 'react';
import { css } from '@emotion/react/macro';

type Props = {
  text: string,
  marginTp: string
  mainCost? : ReactNode
}

const ServiceTitle:FC<Props> = memo(({ text, marginTp, mainCost }) => {
  const mainConstTextStyle = css`
  float: right;
  padding-top: 3.5px;
  `;
  const listTitleStyle = css`
  margin-top: ${marginTp}px ;
  width: 100%;
  li {
  position: relative;
  list-style-type: none!important;
  padding: 0.7em 0.7em 0.7em 0.7em;
  margin-bottom: 5px;
  line-height: 1.5;
  background: #224EA8;
  vertical-align: middle;
  color: white;
  border-radius: 25px 25px 25px 25px;
  }

  li:before{ 
  position: relative;
  bottom: 1.5px;
  display:inline-block; 
  vertical-align: middle;
  content:'';
  width:0.8em;
  height: 0.8em;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
  margin-left: 5px
}`;

  return (
    <div css={listTitleStyle}>
      <li>
        {text}
        {
          mainCost && (
            <span css={mainConstTextStyle} style={{ fontSize: '12px' }}>{mainCost}</span>
          )
        }
      </li>
    </div>
  );
});

export default ServiceTitle;
