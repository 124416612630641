import { FC } from 'react';
import ChangePasswordForm from '../../Organisms/Form/ChangePasswordForm';
import AuthHeader from '../../Organisms/header/AuthHeader';
import IndexLayout from '../../Organisms/IndexLayout';
import Footer from '../../Atoms/Footer';

const PasswordReset:FC = () => (
  <>
    <AuthHeader />
    <IndexLayout odd height={850}>
      <ChangePasswordForm />
    </IndexLayout>
    <Footer odd />
  </>
);

export default PasswordReset;
