import {
  FC, memo,
} from 'react';
import { css } from '@emotion/react/macro';

import mainVisual from '../../../assets/mainvisual.svg';
import mainText from '../../../assets/mainvisual_text.svg';
import EnranceButton from '../../Atoms/button/EnranceButton';

const MainVisual: FC = memo(() => (
  <>
    <div css={whiteStyle}>
      <div css={mainVisualBox}>
        <img src={mainVisual} css={mainVisualStyle} alt="メイン画像" />
        <div>
          <img src={mainText} alt="メイン画像" css={textImageStyle} />
          <div css={textStyle}>
            <div className="box">
              GrantDriveはBoxのオープンリンク(共有リンク)を
              <br />
              自動でパスワード必須化して共有することが
              <br />
              できるサービスです。
              <br />
              <br />
              <br />
              <br />
              PPAPの代替手段として、Box共有リンクを利用し
              <br />
              運用するために必要な仕組みをご提供します。
              <br />
              <EnranceButton
                text="トライアル申し込み・お問い合わせ"
                marginTp="30px"
                width="62%"
                floated="left"
                size="mini"
                index
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
));

const mainVisualBox = css`
  background-color: #fff;
  position: relative;
  min-width: 1024px;
  max-width: 1400px;
  height: 700px;
  margin-left:auto !important;
  margin-right:auto !important;
  overflow: hidden;
`;
const textStyle = css`
  color: ##0D0D0D;
  position: absolute;
  width: 40%;
  top: 300px;
  left: 100px;
  font-size: auto;
  line-height: 1.5em;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
`;

const whiteStyle = css`
background-color:  #fff;
`;
const mainVisualStyle = css`
  position: absolute;
  width: 1700px;
  bottom: -50px;
  left: -150px;
`;

const textImageStyle = css`
  position: absolute;
  top: 40px;
  width: 480px;
  margin-left: 20px;
`;

export default MainVisual;
