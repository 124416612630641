import React, { memo } from 'react';
import { css } from '@emotion/react/macro';
import { Form } from 'semantic-ui-react';

type Props = {
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>
  label: string,
  password? : boolean,
  marginTp?: string
  reset? :boolean
}

const AuthForm:React.FC<Props> = memo(({
  value, setValue, label, password = false, marginTp = '40px', reset = false,
}) => {
  const formPosition = css({
    marginTop: marginTp,
    width: !reset ? '100%' : '83%',
    paddingRight: '20px',
    paddingLeft: '20px',
    marginLeft: !reset ? '' : ' 40px',
  });

  return (
    <div css={formPosition}>
      {password ? (
        <Form.Input
          fluid
          label={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="password"
        />
      ) : (
        <Form.Input
          fluid
          label={label}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </div>
  );
});

export default AuthForm;
