import { FC, memo } from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';
import { useHistory } from 'react-router-dom';
import SecondExpansion from '../../../assets/index/HTML.svg';
import SecondVisual from '../../../assets/index/HTMLboxlogoForgelogo.svg';
import ServiceText from '../../Molecules/ServiceText';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { MainVisualText1 } from '../../../text/MainVisualText';
import { URL } from '../../../util';
import EntranceTitle from '../../Atoms/entrance/EntranceTitle';

const MainExpansion:FC = memo(() => {
  const history = useHistory();

  return (
    <Container css={containreMargin} style={{ width: '1024px' }}>
      <Grid textAlign="center">
        <EntranceTitle title="GrantDriveとは" />
      </Grid>
      <Grid>
        <Grid.Row css={columnStyle}>
          <Grid.Column width={6}>
            <Image src={SecondVisual} css={widthStyle} />
          </Grid.Column>
          <Grid.Column width={10}>
            <div css={secondTextMargin}>
              オープンリンクの
              <span css={blueColor}>パスワードを必須化して</span>
              <br />
              <span css={blueColor}>Box共有リンクのスマートな管理を</span>
              <br />
              実現するサービスです。
            </div>
            <ServiceText text={MainVisualText1} mainVisual />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row css={imageGroup}>
          <Image src={SecondExpansion} css={secondImage} />
        </Grid.Row>
      </Grid>
      <Grid textAlign="center">
        <DefaultButton
          text="サービス概要"
          marginTp="80px"
          marginBt="100px"
          width="110%"
          size="huge"
          onClinckFunc={(e) => {
            e.preventDefault();
            history.push(URL.service);
          }}
        />
      </Grid>
    </Container>
  );
});

const secondImage = css`
padding: 50px;
`;

const columnStyle = css`
  margin-bottom: 40px
`;

const containreMargin = css`
  width: 1400px;
`;

const imageGroup = css`
background-color: #fff;
`;

const secondTextMargin = css`
font-size: 28px;
line-height: 1.5em;
margin-left: 20px;
margin-bottom: 40px;
`;

const blueColor = css`
  color: #224EA8
`;

const widthStyle = css`
  width: 100%;
`;

export default MainExpansion;
