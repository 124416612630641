import { FC, memo } from 'react';
import { css } from '@emotion/react/macro';

type Props ={
  text: string[],
  mainVisual? : boolean
}
const ServiceText:FC<Props> = memo(({ text, mainVisual = false }) => {
  const visual = mainVisual ? '0px' : '40px';
  const visualFont = mainVisual ? '16px' : '0.988rem';
  const style = css`
  width: 100%;
  margin-left: ${visual};
  li {
  text-indent: -2em;
  font-size:${visualFont};
  list-style-type: none!important;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin-bottom: 2px;
  line-height: 1.6;
  vertical-align: middle;
  }
  li:before{ 
    position: relative;
    bottom: 1.5px;
    display:inline-block; 
    vertical-align: middle;
    content:'';
    width:0.8em;
    height: 0.8em;
    background: #224EA8;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 5px
  }`;

  return (
    <ul css={style}>
      {text.map((data) => (
        <li key={data}>{data}</li>
      ))}
    </ul>
  );
});

export default ServiceText;
