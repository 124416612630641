import { css } from '@emotion/react/macro';
import { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Segment, Container,
} from 'semantic-ui-react';
import { Context } from '../../../context/ContextProvider';
import { useGetAccountUsers } from '../../../hooks';
import { URL } from '../../../util';
import DefaultButton from '../../Atoms/button/DefaultButton';
import ErrorMassage from '../../Atoms/ErrorMessage';
import Loading from '../../Atoms/Loading';
import SegmentTitle from '../../Atoms/setting/SegmentTitle';
import SettingText from '../../Atoms/setting/SettingText';
import AccountsTable from '../../Molecules/AccountsTable';

const Account: FC = () => {
  const [loading, setIsLoading] = useState(true);

  const { tenantAlias, idtoken, userName } = useContext(Context);
  const history = useHistory();
  const { users, err } = useGetAccountUsers(setIsLoading, tenantAlias, idtoken);
  const onClickHanfole = () => {
    history.push(URL.addAccount);
  };

  return (

    <Container css={segmntMargin}>
      <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
        <SegmentTitle
          rightText={(<DefaultButton text="ユーザー追加" marginTp="0" onClinckFunc={onClickHanfole} size="mini" floated="right" />)}
        >
          アカウント管理
        </SegmentTitle>
        <div style={{ padding: '20px 10px 10px' }}>
          <SettingText>
            <p>GrantDriveお客様ポータルにログインする、お客様の管理者アカウント情報について登録・編集・削除を行います。</p>
          </SettingText>
        </div>
        {err && <ErrorMassage errMessage={err} />}
        {loading && (<Loading />)}
        { !err && !loading
        && (
        <AccountsTable
          users={users}
          me={userName}
          idToken={idtoken}
          tenantAlias={tenantAlias}
        />
        )}
      </Segment>
    </Container>
  );
};

const segmentWidth = css({
  maxWidth: '900px',
});
const segmntMargin = css({
  marginTop: '30px',
  minHeight: '770px',
  height: 'calc(100vh -300px)',
});

export default Account;
