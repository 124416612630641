import { css } from '@emotion/react';
import { FC, ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

type Props ={
  children: ReactNode,
  rightText?: string | React.ReactNode
}

const SegmentTitle: FC<Props> = ({ children, rightText }) => (
  <Table basic="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell css={{ fontSize: '16px' }}>
          {children}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right" css={headerRight}>
          {
            typeof (rightText) === 'string'
            && (<p css={headerRight}>{rightText}</p>)
          }
          {
            typeof (rightText) !== 'string'
            && (rightText)
          }
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  </Table>
);

export default SegmentTitle;

const headerRight = css({
  textAlign: 'right',
  color: 'rgb(156, 166, 177)',
  top: '0.5%',
  fontSize: '12px',
});

// const titleStyle = css`
//   background: transparent;
//   box-shadow: none;
//   font-size: 1em;
//   width: 100%;
//   background: #FFFFFF;
//   margin: 1em 0em;
//   border: 1px solid rgba(34, 36, 38, 0.15);
//   border-radius: 0.28571429rem;
//   text-align: left;
//   color: rgba(0, 0, 0, 0.87);
//   border-collapse: separate;
//   border-spacing: 0px;
// `
