import { FC, ReactNode } from 'react';
import {
  Button, Modal, SemanticCOLORS, Label,
} from 'semantic-ui-react';

type Props ={
  onClickFunc: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
  open: boolean,
  title: string | ReactNode,
  content: string | ReactNode,
  buttonText: string
  color? : SemanticCOLORS
  size? :'large' | 'mini' | 'tiny' | 'small' | 'fullscreen' | undefined,
  isOpen: React.Dispatch<React.SetStateAction<boolean>>
  disabled: boolean,
}

const CreditDialog: FC<Props> = ({
  onClickFunc, open, title, content, buttonText, color = 'blue', size = 'tiny', isOpen, disabled,
}) => (
  <Modal open={open} size={size}>
    <Modal.Header>
      {title}
    </Modal.Header>
    <Modal.Content>
      {content}
    </Modal.Content>
    <Modal.Actions>
      <Label basic color="red" pointing="right">
        ※ボタンは一度だけクリックしてください。
      </Label>

      <Button color={color} onClick={onClickFunc} disabled={disabled}>{buttonText}</Button>
      <Button color="red" onClick={() => isOpen(false)}>戻る</Button>
    </Modal.Actions>
  </Modal>
);

export default CreditDialog;
