import { css } from '@emotion/react/macro';
import React, { FC, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid, Segment, Container, SemanticCOLORS,
} from 'semantic-ui-react';
import { Context } from '../../../context/ContextProvider';
import { ContactRepository, getCntractInfo } from '../../../Repository/ContactRepository';
import ContractInfoTable from '../../Molecules/ContractInfoTable';
import DefaultButton from '../../Atoms/button/DefaultButton';
import SettingText from '../../Atoms/setting/SettingText';
import Loading from '../../Atoms/Loading';
import { URL } from '../../../util';
import ComfirmdDalog from '../../Atoms/ComfirmdDalog ';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';
import ErrorMassage from '../../Atoms/ErrorMessage';

type Props ={
  loading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  contactInfo: getCntractInfo
  err: string
}

const ServiceStopRequest: FC<Props> = ({
  loading, contactInfo, setIsLoading, err,
}) => {
  const { idtoken, tenantAlias } = useContext(Context);
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState<SemanticCOLORS>('blue');
  const onClickFunc = () => {
    setOpen(false);
    history.push(URL.dashboard);
  };
  const StopServiceHandle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setButtonDisable(true);
    // 連続押下させないようにわざと処理をまつ
    setTimeout(() => {
      void ContactRepository(idtoken).stopService(tenantAlias)
        .then((result) => {
          if (result) {
            setButtonDisable(false);
            setTitle('契約解除完了');
            setContent('契約解除が完了しました。');
            setIsLoading(false);
            setOpen(true);
          } else {
            setButtonDisable(false);
            setTitle('ネットワークエラー');
            setContent('契約の解除に失敗しました。再度お試しください。');
            setColor('red');
            setOpen(true);
          }
        })
        .catch((_) => {
          setIsLoading(false);
        });
      setButtonDisable(false);
    }, 500);
  };

  return (
    <Container css={segmntMargin}>
      <ComfirmdDalog
        title={title}
        content={content}
        open={open}
        color={color}
        buttonText="ダッシュボードに戻る"
        onClickFunc={() => onClickFunc()}
      />

      <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
        <SegmentHeader>
          利用解除申し込み
        </SegmentHeader>
        <div style={{ padding: '20px' }}>
          <SettingText>
            <p>下記のGrantDriveの利用解除を申し込みます</p>
          </SettingText>
          {err && <ErrorMassage errMessage={err} />}
          {loading && (<Loading />)}
          {!err && !loading && (
          <ContractInfoTable
            tenantContractEnd={contactInfo.tenantContractEnd}
            tenantContractStart={contactInfo.tenantContractStart}
            tenantContractType={contactInfo.tenantContractType}
            tenantMaximumAccount={contactInfo.tenantMaximumAccount}
            tenantStatus={contactInfo.tenantStatus}
            tenantStatusName={contactInfo.tenantStatusName}
          />
          )}
          {
            !err && !loading && (
              <>
                <Grid.Row textAlign="center" verticalAlign="middle" css={columStyle}>
                  <Grid.Column textAlign="center">
                    {
                    contactInfo.tenantContractType !== '請求書払い'
                      ? (contactInfo.tenantStatus === 12 || contactInfo.tenantStatus === 22)
                        && <p css={pStyle}>GrantDriveの利用を解除してよろしいですか？</p>
                      : (
                        <>
                          <p css={pStyle}>
                            請求書払いでの利用解除申し込みについては、
                            <br />
                            弊社営業担当者または下記のメールアドレスまでご連絡いただけますようお願いいたします。
                            <br />
                          </p>
                          <p css={pStyle}>GrantDriveサポート連絡先: support@grantdrive.jp</p>
                        </>
                      )
                  }
                  </Grid.Column>
                </Grid.Row>
                {
                  (contactInfo.tenantContractType !== '請求書払い' && (contactInfo.tenantStatus === 12 || contactInfo.tenantStatus === 22)) && (
                    <Grid.Row textAlign="center" verticalAlign="middle" css={buttonMargin}>
                      {
                        buttonDisable ? (<Loading />)
                          : (
                            <DefaultButton
                              text="利用を解除する"
                              marginTp="15px"
                              onClinckFunc={(e) => StopServiceHandle(e)}
                              width="40%"
                            />
                          )
                      }
                    </Grid.Row>
                  )
                }
              </>
            )
          }
        </div>
      </Segment>
    </Container>
  );
};

const segmentWidth = css({
  maxWidth: '900px',
});
const segmntMargin = css({
  marginTop: '30px',
  minHeight: '770px',
  height: 'calc(100vh - 300px)',
});

const pStyle = css({
  textAlign: 'center',
  color: '#898989',
});
const columStyle = css({
  marginTop: '30px',
});

const buttonMargin = css({
  marginBottom: '30px',
});

export default ServiceStopRequest;
