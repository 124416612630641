/* eslint-disable react/jsx-closing-bracket-location */
import React, { createContext, useState } from 'react';

type ContextType = {
  idtoken: string | undefined;
  setIdToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  accessToken: string | undefined;
  setAccessToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  userName: string | undefined;
  setUserName: React.Dispatch<React.SetStateAction<string | undefined>>;
  tenantAlias: string;
  setTenantAlias: React.Dispatch<React.SetStateAction<string>>;
  changeFlag: boolean;
  setChangeFlag: React.Dispatch<React.SetStateAction<boolean>>;
};
export const Context = createContext({} as ContextType);

type Props = {
  children: React.ReactNode;
};

const ContextProvider: React.FC<Props> = ({ children }) => {
  const [idtoken, setIdToken] = useState<string | undefined>('');
  const [accessToken, setAccessToken] = useState<string | undefined>('');
  const [isLogin, setIsLogin] = useState(false);
  const [userName, setUserName] = useState<string | undefined>('');
  const [tenantAlias, setTenantAlias] = useState<string>('');
  const [changeFlag, setChangeFlag] = useState(false);

  return (
    <Context.Provider
      value={{
        idtoken,
        setIdToken,
        isLogin,
        setIsLogin,
        accessToken,
        setAccessToken,
        userName,
        setUserName,
        setTenantAlias,
        tenantAlias,
        changeFlag,
        setChangeFlag,
      }}>
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
