import { FC, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';
import { useHistory } from 'react-router';
import IndexLayout from '../../Organisms/IndexLayout';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { URL } from '../../../util';
import Footer from '../../Atoms/Footer';
import PageTitle from '../../Organisms/Layout/PageTitle';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>
}
export type contactHistry = {
  refer: string
}

const ContactDone:FC<Props> = ({ setActive }) => {
  const history = useHistory<contactHistry>();
  useEffect(() => {
    if (!history.location.state
        || history.location.state.refer !== URL.contact) {
      history.push(URL.index);

      return;
    }
    setActive('contact');
  }, [setActive, history]);

  return (
    <>
      <PageTitle odd height={80} center>
        <Grid.Row textAlign="center" verticalAlign="middle" css={serviceTitile}>
          トライアル申し込み・お問い合わせ
        </Grid.Row>
      </PageTitle>
      <IndexLayout height={400} center odd={false} calcPix="215px">
        <Grid.Row textAlign="center" verticalAlign="middle" css={cntactDetails}>
          当社へのお問い合わせ、ありがとうございました。
          <br />
          お問い合わせいただきました内容をもとに担当者よりご連絡いたします。
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="middle" css={cntactSecoundDetails}>
          お問い合わせの内容によっては回答までにお時間を頂く場合がございますのでご了承ください。
          <br />
          よろしくお願いいたします。
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="middle">
          <DefaultButton
            text="TOPへ戻る"
            marginTp="0"
            onClinckFunc={() => history.push(URL.index)}
            width="100%"
          />
        </Grid.Row>
      </IndexLayout>
      <Footer minHeihght="80px" />
    </>
  );
};

const cntactDetails = css({
  fontSize: '14px',
  marginTop: '35px',
});

const cntactSecoundDetails = css({
  fontSize: '14px',
});

const serviceTitile = css({
  fontSize: '20px',
});

export default ContactDone;
