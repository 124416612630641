import { FC } from 'react';
import { Message } from 'semantic-ui-react';
import { PasswordPolicyText } from '../../text/PasswordPolicyText';

const PasswordPolicyMessage:FC = () => (
  <Message
    color="blue"
    icon="info circle"
    size="mini"
    header="パスワードポリシー"
    list={PasswordPolicyText}
  />
);

export default PasswordPolicyMessage;
