import { useState, useEffect } from 'react';
import { StateRepository, eventsResponce } from '../Repository/StateRepository';

type useGetContactInfoType = {
  events: eventsResponce;
  err: string;
};
const initialGetCntractInfo: eventsResponce = [
  {
    createdUser: '',
    createtAt: '',
    fileName: '',
  },
];
export const useGetEventsState = (
  idToken: string | undefined,
  tenantAlias: string,
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>,
): useGetContactInfoType => {
  const [events, setEvents] = useState<eventsResponce>(initialGetCntractInfo);
  const [err, setErr] = useState('');
  useEffect(() => {
    setIsloading(true);
    StateRepository(idToken)
      .getEventsState(tenantAlias)
      .then((responce) => {
        setEvents(responce);
        setIsloading(false);
      })
      .catch(() => {
        setErr('エラーが発生しました。しばらくしてからお試しください。');
        setIsloading(false);
      });
  }, [setIsloading, tenantAlias, idToken]);

  return { events, err };
};
