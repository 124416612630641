import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Icon, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import SegmentTitle from '../../Atoms/setting/SegmentTitle';
import SettingText from '../../Atoms/setting/SettingText';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { URL } from '../../../util';
import Footer from '../../Atoms/Footer';

type Props = {
  setActive: React.Dispatch<React.SetStateAction<string>>
}
export type historyType ={
  textTitle: string,
  text: string,
  referrer: string,
}
const CreditDone:FC<Props> = ({ setActive }) => {
  const history = useHistory<historyType>();
  const [textTitle, setTextTitle] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    if (history.location.state
      && (
        history.location.state.referrer === URL.credit
        || history.location.state.referrer === URL.creditChange
      )
    ) {
      setTextTitle(history.location.state.textTitle);
      setText(history.location.state.text);
    } else {
      history.push(URL.dashboard);
    }
  }, [history]);

  const onClickHandle = () => {
    setActive('dashboard');
    history.push(URL.dashboard);
  };

  return (
    <>
      <Container css={segmntMargin}>
        <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
          <SegmentTitle>
            {textTitle}
          </SegmentTitle>
          <SettingText center>
            <Icon name="check circle outline" size="huge" color="green" css={iconStyle} />
            <div css={divStyle}>
              <h2 css="center">
                {text}
                が完了しました
              </h2>
              <ul>
                {text !== '決済' && (
                  <li>次回の請求から反映されます。</li>
                )}
                {text === '決済' && (
                  <>
                    <li>本番環境が出来上がるまで5分ほどかかります。</li>
                    <li>しばらく経ってからアクセスしてください。</li>
                  </>
                )}
                <li>ステータスはダッシュボードのご契約状況からご確認が可能です。</li>
              </ul>
            </div>
          </SettingText>
          <div css={buttonBottom}>
            <DefaultButton
              text="TOPへ戻る"
              marginTp="50px"
              onClinckFunc={onClickHandle}
              width="30%"
            />
          </div>
        </Segment>
      </Container>
      <Footer odd />
    </>
  );
};

export default CreditDone;

const iconStyle = css({
  margin: '3rem auto !important',
  display: 'block !important',
});

const segmntMargin = css({
  marginTop: '48px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

const buttonBottom = css({
  marginBottom: '100px',
});
const divStyle = css`
  p, h2 {
    text-align: center;
  }
  ul {
    width: 70%;
    margin: 2rem auto;
  }
`;
