/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  useEffect, FC, ReactNode, useState,
} from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { URL } from '../../../util';

import { userPool } from '../../../Repository/Cognito';

type Props = {
  children: ReactNode
}
const SessionCheck:FC<Props> = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const user = userPool.getCurrentUser();
    if (user === null) {
      return;
    } user.getSession(
      (error: null, session: CognitoUserSession) => {
        if (error) {
          return;
        }
        if (session !== null) {
          setIsLogin(true);
        }
      },
    );
  }, [history]);

  return isLogin ? <Redirect to={URL.dashboard} /> : <>{children}</>;
};

export default SessionCheck;
