/* eslint-disable arrow-body-style */
import { FC, useState, useContext } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Form, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import { Context } from '../../../context/ContextProvider';
import SegmentTitle from '../../Atoms/setting/SegmentTitle';
import DefaultFormInput from '../../Molecules/DefaultFormInput';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { AccountRepository } from '../../../Repository/AccountRepository';
import { AddAccountInit, AddAccountValidType } from '../../../util/validate/AddAccountValidation';
import ComfirmdDalog from '../../Atoms/ComfirmdDalog ';
import { URL } from '../../../util';
import PasswordPolicyMessage from '../../Atoms/PasswordPolicyMessage';

const AddAccountForm:FC = () => {
  const history = useHistory();
  const { idtoken, tenantAlias } = useContext(Context);
  const [err, setErr] = useState<AddAccountValidType>(AddAccountInit);
  const [isErrModal, setIsErrModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [rePasswordErr, setRePasswordErr] = useState('');
  const [disable, setDisable] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setComfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [department, setDepartment] = useState('');
  const signUpUser = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setErr(AddAccountInit);
    setRePasswordErr('');
    e.preventDefault();
    setDisable(true);
    if (password !== confirmPassword) {
      setDisable(false);
      setRePasswordErr('パスワードが一致しません。');

      return;
    }

    void AccountRepository(tenantAlias, idtoken)
      .SignUpUser({
        name,
        email,
        password,
        confirmPassword,
        department,
        phoneNumber,
      }, setErr)
      .then((status) => {
        if (status === 400) {
          setDisable(false);

          return;
        }
        if (status === 200) {
          setDisable(false);
          setOpen(true);

          return;
        }
        setIsErrModal(true);
        setDisable(false);
      });
  };

  return (
    <>
      <ComfirmdDalog
        title="ユーザー追加"
        open={open}
        content="ユーザーアカウントを追加しました。"
        onClickFunc={() => {
          setOpen(false);
          history.push(`${URL.settings}?account`);
        }}
        buttonText="アカウント一覧へ"
      />
      <ComfirmdDalog
        title="ユーザー追加"
        open={isErrModal}
        content="ユーザーアカウントの追加に失敗しました。"
        onClickFunc={() => setIsErrModal(false)}
        buttonText="OK"
        color="red"
      />
      <Container css={segmntMargin} text>
        <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
          <SegmentTitle rightText="Grant Driveユーザーを追加します">
            ユーザー追加
          </SegmentTitle>
          <Form css={buttonBottom}>
            <DefaultFormInput
              labelText="氏名"
              placeholder="フォージ 太郎"
              value={name}
              setValue={setName}
              error={err.name.errorFlag}
              errorContent={err.name.message}
              required
            />
            <DefaultFormInput
              labelText="E-Mail"
              placeholder="info@forgevision.com "
              value={email}
              setValue={setEmail}
              error={err.email.errorFlag}
              errorContent={err.email.message}
              required
            />
            <DefaultFormInput
              labelText="電話番号"
              placeholder="0312345678"
              value={phoneNumber}
              setValue={setPhoneNumber}
              error={err.phoneNumber.errorFlag}
              errorContent={err.phoneNumber.message}
            />
            <DefaultFormInput
              labelText="部署名"
              placeholder="総務"
              value={department}
              setValue={setDepartment}
              error={err.departmentName.errorFlag}
              errorContent={err.departmentName.message}
            />
            <DefaultFormInput
              labelText="パスワード"
              placeholder=""
              value={password}
              setValue={setPassword}
              error={err.password.errorFlag}
              errorContent={err.password.message}
              required
              password
            />
            <DefaultFormInput
              labelText="パスワード確認"
              placeholder=""
              value={confirmPassword}
              setValue={setComfirmPassword}
              error={rePasswordErr !== ''}
              errorContent={rePasswordErr}
              required
              password
            />
            <Form.Field>
              <DefaultButton
                text="ユーザーを追加する"
                marginTp="20px"
                width="30%"
                onClinckFunc={(e) => signUpUser(e)}
                disabled={disable}
                accountCancelButton
              />
            </Form.Field>
          </Form>
          <PasswordPolicyMessage />
        </Segment>
      </Container>
    </>
  );
};

const segmntMargin = css({
  marginTop: '30px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

const buttonBottom = css({
  marginBottom: '40px',
});

export default AddAccountForm;
