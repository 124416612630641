/* eslint-disable @typescript-eslint/prefer-regexp-exec */

import { FC } from 'react';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form';
import { css } from '@emotion/react/macro';
import { Icon, Radio } from 'semantic-ui-react';
import ReactTagInput from '../tagInput';

type Props = {
  title: string,
  sharedlinkBlackWhite: number,
  setSharedlinkBlackWhite: React.Dispatch<React.SetStateAction<number>>,
  tagsBlackList: string[],
  setTagsBlackList: React.Dispatch<React.SetStateAction<string[]>>,
  errBlackList: boolean,
  errBlackListContent: string,
  tagsBlackIdList: string[],
  setTagsBlackIdList: React.Dispatch<React.SetStateAction<string[]>>,
  errBlackIdList: boolean,
  errBlackIdListContent: string,
  tagsWhiteList: string[],
  setTagsWhiteList: React.Dispatch<React.SetStateAction<string[]>>,
  errWhiteList: boolean,
  errWhiteListContent: string,
  tagsWhiteIdList: string[],
  setTagsWhiteIdList: React.Dispatch<React.SetStateAction<string[]>>,
  errWhiteIdList: boolean,
  errWhiteIdListContent: string,
}

const WhiteBlackList:FC<Props> = ({
  title, sharedlinkBlackWhite, setSharedlinkBlackWhite,
  tagsBlackList, setTagsBlackList,
  errBlackList, errBlackListContent,
  tagsBlackIdList, setTagsBlackIdList,
  errBlackIdList, errBlackIdListContent,
  tagsWhiteList, setTagsWhiteList,
  errWhiteList, errWhiteListContent,
  tagsWhiteIdList, setTagsWhiteIdList,
  errWhiteIdList, errWhiteIdListContent,
}) => {
  // 除外 or 対象
  const SHAREDLINK_BLACK = 1;
  const SHAREDLINK_WHITE = 2;
  const changeBlackWhite = (e:React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (sharedlinkBlackWhite === SHAREDLINK_BLACK) {
      setSharedlinkBlackWhite(SHAREDLINK_WHITE);
    } else {
      setSharedlinkBlackWhite(SHAREDLINK_BLACK);
    }
  };

  const checkNumeric = (value:string, idname?:string): boolean => {
    const msgDiv = idname !== undefined ? document.getElementById(idname) : null;
    const isNumeric = Boolean(String(value).match(/^\d+$/));

    if (msgDiv !== null) {
      msgDiv.classList.add('hidden');
      if (!isNumeric) {
        msgDiv.classList.remove('hidden');
      }
    }

    // Return boolean to indicate validity
    return isNumeric;
  };

  function validateErrIdMessage(idname: string) {
    return (
      <>
        <div id={idname} className="ui pointing above prompt label hidden" role="alert" aria-atomic="true">
          オブジェクトIDは数値のみで指定してください
        </div>
      </>
    );
  }

  return (
    <Form.Group css={borderStyle}>
      <Form.Field width="16" css={fieldStyle}>
        <h5>{title}</h5>
        <Form.Field css={subFieldStyle}>
          <Radio
            label="除外するフォルダを指定する"
            value={SHAREDLINK_BLACK}
            checked={sharedlinkBlackWhite === SHAREDLINK_BLACK}
            onChange={(e) => changeBlackWhite(e)}
          />
          <p>
            <Icon name="exclamation circle" />
            指定されたフォルダに対して、パスワード処理を実施しません
          </p>
        </Form.Field>
        <Form.Field
          css={folderFieldStyle}
          style={sharedlinkBlackWhite === SHAREDLINK_BLACK ? null : { opacity: 0.3 }}
        >
          <h5>
            <Icon name="caret right" />
            名称で指定する
          </h5>
          <ReactTagInput
            tags={tagsBlackList}
            onChange={(newTags) => setTagsBlackList(newTags)}
            placeholder="入力後、エンターキーを押下"
            addOnBlur
          />
          {
            errBlackList
            && (<div className="ui pointing above prompt label" role="alert" aria-atomic="true">{errBlackListContent}</div>)
          }
          <h5>
            <Icon name="caret right" />
            オブジェクトIDで指定する
          </h5>
          <ReactTagInput
            tags={tagsBlackIdList}
            onChange={(newTags) => setTagsBlackIdList(newTags)}
            placeholder="数値のみ入力後、エンターキーを押下"
            addOnBlur
            validator={(value) => checkNumeric(value, 'BlackIdListInvalid')}
            validateErrId="BlackIdListInvalid"
          />
          {
            errBlackIdList
            && (<div className="ui pointing above prompt label" role="alert" aria-atomic="true">{errBlackIdListContent}</div>)
          }
          { validateErrIdMessage('BlackIdListInvalid') }
        </Form.Field>
        <Form.Field css={subFieldStyle}>
          <Radio
            label="対象とするフォルダを制限する"
            value={SHAREDLINK_WHITE}
            checked={sharedlinkBlackWhite === SHAREDLINK_WHITE}
            onChange={changeBlackWhite}
          />
          <p>
            <Icon name="exclamation circle" />
            指定されたフォルダにのみ、パスワード処理を実施します
          </p>
        </Form.Field>
        <Form.Field
          css={folderFieldStyle}
          style={sharedlinkBlackWhite === SHAREDLINK_WHITE ? null : { opacity: 0.3 }}
        >
          <h5>
            <Icon name="caret right" />
            名称で指定する
          </h5>
          <ReactTagInput
            tags={tagsWhiteList}
            onChange={(newTags) => setTagsWhiteList(newTags)}
            placeholder="入力後、エンターキーを押下"
            addOnBlur
          />
          {
            errWhiteList
            && (<div className="ui pointing above prompt label" role="alert" aria-atomic="true">{errWhiteListContent}</div>)
          }
          <h5>
            <Icon name="caret right" />
            オブジェクトIDで指定する
          </h5>
          <ReactTagInput
            tags={tagsWhiteIdList}
            onChange={(newTags) => setTagsWhiteIdList(newTags)}
            placeholder="数値のみ入力後、エンターキーを押下"
            addOnBlur
            validator={(value) => checkNumeric(value, 'WhiteIdListInvalid')}
            validateErrId="WhiteIdListInvalid"
          />
          {
            errWhiteIdList
            && (<div className="ui pointing above prompt label" role="alert" aria-atomic="true">{errWhiteIdListContent}</div>)
          }
          { validateErrIdMessage('WhiteIdListInvalid') }
        </Form.Field>
      </Form.Field>
    </Form.Group>
  );
};

const borderStyle = css({
  borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
});

const fieldStyle = css`
  margin-bottom: 20px !important;

  h5:first-child {
    margin-bottom: 0em;
  }

  .hidden {
    display: none;
  }
`;

const subFieldStyle = css`
  display: flex;
  justify-content: space-between;
  margin-top: .8rem !important;
  padding: 1rem 0;

  div.radio {
    margin-left: .5rem;
  }

  p {
    color: #224ea8c7;
    margin-left: 1rem;
  }
`;

const folderFieldStyle = css`
  padding: 1rem 2rem 2rem;
  background-color: #edf2f9;
  border-radius: .3rem;

  div {
    background-color: #edf2f9;
  }

  .react-tag-input__tag__content,
  .react-tag-input__tag__remove,
  .react-tag-input__tag {
    color: #fff;
    background: #767676 !important;
    font-size: 13px;
  }
  .react-tag-input__tag__remove:before, .react-tag-input__tag__remove:after {
    background: #fff; /* X icon in remove button */
  }
`;

export default WhiteBlackList;
