import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from '../context/ContextProvider';
import Router from '../router/Router';

const App: FC = () => (
  <BrowserRouter>
    <ContextProvider>
      <Router />
    </ContextProvider>
  </BrowserRouter>
);

export default App;
