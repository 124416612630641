import { FC, useEffect } from 'react';
import MainVisual from '../Organisms/entrance/MainVisual';
import MainExpansion from '../Organisms/entrance/MainExpansion';
import MainSecondExpansion from '../Organisms/entrance/MainSecondExpansion';
import MainCost from '../Organisms/entrance/MainCost';
import Footer from '../Atoms/Footer';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const Index: FC<Props> = ({ setActive }) => {
  useEffect(() => {
    setActive('');
    document.title = 'GrantDrive | Box共有リンクパスワード自動付与サービス 〜 GrantDrive for Box';
  }, [setActive]);

  return (
    <>
      <MainVisual />
      <MainExpansion />
      <MainSecondExpansion />
      <MainCost />
      <Footer minHeihght="60px" />
    </>
  );
};

export default Index;
