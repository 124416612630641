/* eslint-disable max-len */
import { css } from '@emotion/react';
import { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { ServiceStateText } from '../../text/ServiceStateText';

type Props ={
  grantDriveStatus: number,
  boxApiStatus: number
}

const ServiceStateTable:FC<Props> = ({ grantDriveStatus, boxApiStatus }) => {
  const gdIcon = grantDriveStatus === 1 ? 'check circle' : 'exclamation triangle';
  const gdColor = grantDriveStatus === 1 ? 'green' : 'yellow';
  const gdMessage = grantDriveStatus === 1 ? ServiceStateText.grantDriveStatus : ServiceStateText.grantDriveStatusErr;
  const boxIcon = boxApiStatus === 1 ? 'check circle' : 'exclamation triangle';
  const boxColor = boxApiStatus === 1 ? 'green' : 'yellow';
  const boxMessage = boxApiStatus === 1 ? ServiceStateText.boxApiStatus : ServiceStateText.boxApiStatusErr;

  return (
    <table css={stateTableStyle} style={{ marginTop: '0' }}>
      <tbody>
        <tr>
          <td>
            <div css={leftColumn}>GrantDrive</div>

          </td>
          <td>
            {' '}
            <Icon name={gdIcon} color={gdColor} />
            {' '}
            { gdMessage }
          </td>
          <td />
        </tr>
        <tr>
          <td>
            <div css={leftColumn}>Box API</div>
          </td>
          <td className="table">
            {' '}
            <Icon name={boxIcon} color={boxColor} />
            {' '}
            { boxMessage }
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  );
};

export default ServiceStateTable;

const leftColumn = css({
  color: 'rgb(156, 166, 177)',
  marginRight: '5px',
});

const stateTableStyle = css`
  border: none;
  box-shadow: none;
  font-size: 1em;
  width: 100%;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  border-spacing: 0px;
  margin: 0em;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  tbody > tr {
    display: table-row;
    height: 5rem;
    vertical-align: inherit;
    border-color: inherit;
    font-size: 15px;
    white-space: nowrap;
    margin: 5px;
  }
  td {
    padding: 0.78571429em 0.78571429em;
    transition: background 0.1s ease, color 0.1s ease;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  tr:nth-of-type(2) > td {
    border: none;
  }

  td:nth-of-type(1) {
    text-align: right;
    vertical-align: middle;
    width: 18.75%;
    color: #898989;
  }
  td:nth-of-type(3){
    text-align: right;
  }
`;
