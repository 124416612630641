import { FC } from 'react';
import ServiceStopDone from './ServiceStopDone';
import ServiceStopRequest from './ServiceStopRequest';
import Loading from '../../Atoms/Loading';
import { getCntractInfo } from '../../../Repository/ContactRepository';

type Props ={
  setActive: React.Dispatch<React.SetStateAction<string>>
  contractInfo: getCntractInfo,
  loading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  err: string
}

const ServiceStop:FC<Props> = ({
  setActive, contractInfo, loading, setIsLoading, err,
}) => {
  let element;
  if (contractInfo.tenantStatus === 30 || contractInfo.tenantStatus === 31) {
    element = (
      <>
        { loading && (<Loading />)}
        {!loading && (<ServiceStopDone setActive={setActive} />) }
      </>
    );
  } else {
    element = (
      <ServiceStopRequest
        loading={loading}
        setIsLoading={setIsLoading}
        contactInfo={contractInfo}
        err={err}
      />
    );
  }

  return element;
};

export default ServiceStop;
