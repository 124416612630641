import { useEffect, FC } from 'react';

import AddAccountForm from '../../Organisms/dashBoard/AddAccountForm';
import PortalHeader from '../../Organisms/header/PortalHeader';
import Footer from '../../Atoms/Footer';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const AddAccount:FC<Props> = ({ active, setActive }) => {
  useEffect(() => {
    setActive('settings');
  }, [setActive]);

  return (
    <>
      <PortalHeader active={active} setActive={setActive} />
      <AddAccountForm />
      <Footer odd minHeihght="30vh" />
    </>
  );
};

export default AddAccount;
