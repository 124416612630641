import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { Image, Container, Grid } from 'semantic-ui-react';

// import IndexLayout from '../IndexLayout';
import logo from '../../../assets/GrantDrive_logo.svg';

const AuthHeader: FC = memo(() => {
  const containerStyle = css({
    height: '60px',
    backgroundColor: '#fff',
    overflowY: 'auto',
    alignItems: 'center',
    display: 'flex',
  });

  return (
    <div css={containerStyle}>
      <Container text>
        <Grid columns={2} textAlign="center">
          <a href="/">
            <Image src={logo} />
          </a>
        </Grid>
      </Container>
    </div>
  );
});

export default AuthHeader;
