import { FC, ReactNode } from 'react';
import IndexHeader from '../header/IndexHeader';

type Props = {
  children : ReactNode,
  active: string
  setActive:React.Dispatch<React.SetStateAction<string>>
}
const EntranceLayout:FC<Props> = ({ children, active, setActive }) => (
  <>
    <IndexHeader active={active} setActive={setActive} />
    {children}
  </>
);

export default EntranceLayout;
