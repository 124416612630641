import { FC } from 'react';
import { Message } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';

type Props = {
  errMessage: string
}
const ErrorMassage:FC<Props> = ({ errMessage }) => (
  <Message negative css={messageStyle}>
    {errMessage}
  </Message>
);

const messageStyle = css({
  textAlign: 'center',
});

export default ErrorMassage;
