import React, { memo } from 'react';
import { css } from '@emotion/react';
import { useHistory, Link } from 'react-router-dom';
import {
  Visibility, Menu, Image, Icon,
} from 'semantic-ui-react';

import logo from '../../../assets/GrantDrive_logo.svg';

type Props ={
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const IndexHeader:React.FC<Props> = memo(({ active, setActive }) => {
  const history = useHistory();

  const onClickHandle = (key: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setActive(key);
    history.push(`/${key}`);
  };

  const onClickHandleSupport = () => {
    window.open('https://support.grantdrive.jp/hc/ja', '_blank');
  };

  return (
    <Visibility once css={headerLayout}>
      <Menu pointing secondary size="large" color="blue">
        <Menu.Item as="a" onClick={(e) => onClickHandle('', e)} css={maginDelete}>
          <Image src={logo} css={logoPosition} />
        </Menu.Item>
        <Menu.Item
          active={active === 'service'}
          onClick={(e) => onClickHandle('service', e)}
          as="a"
          link
          css={maginDelete}
        >
          サービス概要
        </Menu.Item>
        <Menu.Item
          active={active === 'contact'}
          onClick={(e) => onClickHandle('contact', e)}
          as="a"
          link
          css={maginDelete}
        >
          トライアル申し込み・お問い合わせ
        </Menu.Item>
        <Menu.Item
          onClick={() => onClickHandleSupport()}
          as="a"
          link
          css={maginDelete}
        >
          サポートサイト
          <Icon className="window restore outline" />
        </Menu.Item>
        <Menu.Item position="right" css={loginPosition}>
          <Link to="/login">
            ログイン
          </Link>
        </Menu.Item>
      </Menu>
    </Visibility>
  );
});

const headerLayout = css({
  backgroundColor: '#fff',
});
const loginPosition = css({
  right: '20px',
});
const logoPosition = css({
  top: '3px',
  marginRight: '15px',
});

const maginDelete = css({
  margin: '0px !important',
});

export default IndexHeader;
