/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable default-case */
export const AddAccountInit: AddAccountValidType = {
  name: {
    message: '',
    errorFlag: false,
  },
  email: {
    message: '',
    errorFlag: false,
  },
  phoneNumber: {
    message: '',
    errorFlag: false,
  },
  departmentName: {
    message: '',
    errorFlag: false,
  },
  password: {
    message: '',
    errorFlag: false,
  },
};

export type AddAccountValidType = {
  'name': {
    message: string,
    errorFlag: boolean,
  },
  'email': {
    message: string,
    errorFlag: boolean,
  },
  'phoneNumber': {
    message: string,
    errorFlag: boolean,
  },
  'departmentName': {
    message: string,
    errorFlag: boolean,
  },
  'password': {
    message: string,
    errorFlag: boolean,
  },

}

export class AddAccountValidation {
  private addAccountErr: AddAccountValidType = {
    name: {
      message: '',
      errorFlag: false,
    },
    email: {
      message: '',
      errorFlag: false,
    },
    phoneNumber: {
      message: '',
      errorFlag: false,
    },
    departmentName: {
      message: '',
      errorFlag: false,
    },
    password: {
      message: '',
      errorFlag: false,
    },
  };

  setErrorMassage(messages: []):void {
    messages.forEach((message:any) => {
      const loc = message.loc ? message.loc[0] : message.code;
      switch (loc) {
        case 'name':
          this.addAccountErr.name.message = message.message;
          this.addAccountErr.name.errorFlag = true;
          break;
        case 'email':
          this.addAccountErr.email.message = message.message;
          this.addAccountErr.email.errorFlag = true;
          break;
        case 'phone_number':
          this.addAccountErr.phoneNumber.message = message.message;
          this.addAccountErr.phoneNumber.errorFlag = true;
          break;
        case 'department_name':
          this.addAccountErr.departmentName.message = message.message;
          this.addAccountErr.departmentName.errorFlag = true;
          break;
        case 'password':
          this.addAccountErr.password.message = message.message;
          this.addAccountErr.password.errorFlag = true;
          break;
        case 'V400000012':
          this.addAccountErr.email.message = message.message;
          this.addAccountErr.email.errorFlag = true;
          break;
      }
    });
  }

  getObj():AddAccountValidType {
    return this.addAccountErr;
  }
}
