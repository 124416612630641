/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Form, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import DefaultFormInput from '../../Molecules/DefaultFormInput';
import DefaultFormCheckbox from '../../Molecules/DefaultFormCheckbox';
import DefaultButton from '../../Atoms/button/DefaultButton';
import { ContactRepository } from '../../../Repository/ContactRepository';
import ComfirmdDalog from '../../Atoms/ComfirmdDalog ';
import { initContactErr, contactValidType } from '../../../util/validate/ContactValidation';
import { URL } from '../../../util';

const ContactForm: React.FC = () => {
  const [contactType, setContactType] = useState('');
  const [name, setName] = useState('');
  const [kanaName, setKanaName] = useState('');
  const [company, setCompany] = useState('');
  const [mail, setMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [boxUserCount, setBoxUserCount] = useState('');
  const [boxId, setBoxId] = useState('');
  const [question, setQuestion] = useState('');
  const [agree, setAgree] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState<contactValidType>(initContactErr);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const history = useHistory();

  const onSubmit = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsLoading(true);
    e.preventDefault();
    await ContactRepository().post({
      name, contactType, kanaName, company, mail, phoneNumber, boxId, boxUserCount, question,
    }, setErr, setTitle, setContent)
      .then((is) => {
        if (is) {
          history.push(URL.contactDone, { refer: URL.contact });
        } else {
          setOpen(true);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setTitle('サーバーエラー');
        setContent('申し訳ありませんが、しばらくしてから再度お試しください。');
        setOpen(true);
        setIsLoading(false);
      });
  };

  return (
    <div css={containerStyle}>
      <ComfirmdDalog
        open={open}
        title={title}
        content={content}
        onClickFunc={() => setOpen(!open)}
        buttonText="OK"
      />
      <Container text>
        <Form>
          <DefaultFormCheckbox
            labelText="問い合わせ内容"
            checkboxText1="トライアル利用"
            checkboxText2="サービスに関するご質問"
            setValue={setContactType}
            value={contactType}
            errorFlag={err.contactType.errorFlag}
            errorContent={err.contactType.message}
          />
          <DefaultFormInput
            labelText="氏名"
            required
            value={name}
            setValue={setName}
            placeholder="フォージ　太郎"
            error={err.name.errorFlag}
            errorContent={err.name.message}
          />
          <DefaultFormInput
            labelText="氏名(カナ)"
            required
            value={kanaName}
            setValue={setKanaName}
            placeholder="フォージ　タロウ"
            error={err.kanaName.errorFlag}
            errorContent={err.kanaName.message}
          />
          <DefaultFormInput
            labelText="会社名・団体名"
            required
            value={company}
            setValue={setCompany}
            placeholder="フォージビジョン株式会社"
            error={err.company.errorFlag}
            errorContent={err.company.message}
          />
          <DefaultFormInput
            labelText="E-Mail"
            required
            value={mail}
            setValue={setMail}
            placeholder="info@forgevision.com"
            error={err.mail.errorFlag}
            errorContent={err.mail.message}
          />
          <DefaultFormInput
            labelText="電話番号"
            required
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder="0312345678"
            error={err.phoneNumber.errorFlag}
            errorContent={err.phoneNumber.message}
          />
          <DefaultFormInput
            labelText="BoxユーザーID数"
            value={boxUserCount}
            setValue={setBoxUserCount}
            placeholder=""
            error={err.boxUserCount.errorFlag}
            errorContent={err.boxUserCount.message}
            required={contactType === 'トライアル利用' && true}
          />
          <DefaultFormInput
            labelText="Box EnterpriseID"
            value={boxId}
            setValue={setBoxId}
            placeholder=""
            error={err.boxId.errorFlag}
            errorContent={err.boxId.message}
            required={contactType === 'トライアル利用' && true}
          />
          <DefaultFormInput
            labelText="ご質問・ご要望内容"
            value={question}
            setValue={setQuestion}
            placeholder=""
            required={contactType === 'サービスに関するご質問' && true}
            textArea
            error={err.question.errorFlag}
            errorContent={err.question.message}
          />
        </Form>
        <Grid.Row textAlign="center" verticalAlign="middle" css={cntactDetails}>
          下記の内容をご確認を上、同意いただける場合は「個人情報の取り扱いに同意する」
          <br />
          にチェックいただき、確認画面にお進みください。
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="middle" css={cntactDetails}>
          <Form.Checkbox
            label={(
              <label>
                <a href="https://forgevision.com/privacy/" target="blank" css={agrereStyle}>個人情報の取り扱い</a>
                に同意する
              </label>
            )}
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="middle" css={buttonMargin}>
          <DefaultButton
            text="送信"
            onClinckFunc={(e) => onSubmit(e)}
            disabled={isLoading || !agree}
            marginTp="20px"
            width="30%"
          />
        </Grid.Row>
      </Container>
    </div>
  );
};

export default ContactForm;

const containerStyle = css({
  minHeight: '1167px',
  backgroundColor: '#fff',
  height: 'auto',
  paddingBottom: '80px',
});

const cntactDetails = css({
  textAlign: 'center',
  fontSize: '14px',
  marginTop: '15px',
});

const buttonMargin = css({
  textAlign: 'center',
  fontSize: '14px',
  marginTop: '15px',
});
const agrereStyle = css({
  color: '#224EA8',
  borderBottom: 'solid 1px #224EA8',
});
