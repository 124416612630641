/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useEffect } from 'react';

import { getCntractInfo, ContactRepository } from '../Repository/ContactRepository';

type useGetContactInfoType = {
  contactInfo: getCntractInfo;
  err: string;
};
export const initialGetCntractInfo: getCntractInfo = {
  tenantContractStart: '',
  tenantContractType: '',
  tenantMaximumAccount: '',
  tenantStatus: 0,
  tenantStatusName: '',
};

export const useGetContactInfo = (
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>,
  tenantAlias: string,
  idToken: string | undefined,
): useGetContactInfoType => {
  const [contactInfo, setContactInfo] = useState<getCntractInfo>(initialGetCntractInfo);
  const [err, setErr] = useState('');
  useEffect(() => {
    setIsloading(true);
    ContactRepository(idToken)
      .getContractInfo(tenantAlias)
      .then((data) => {
        if (!data) {
          return;
        }
        setContactInfo(data);
        setIsloading(false);
      })
      .catch((e: any) => {
        if (e.status) {
          if (e.response.status === 403 || e.response.status === 404) {
            setErr('');
            setIsloading(false);

            return;
          }
        }
        setErr('エラーが発生しました。しばらくしてからお試しください。');
        setIsloading(false);
      });
  }, [setIsloading, tenantAlias, idToken]);

  return { contactInfo, err };
};
