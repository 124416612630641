export * from './config';
export * from './constant';
export * from './stripe';

export const renameStatus = (status: number): string => {
  switch (status) {
    case 10:
      return 'トライアル申請';
    case 11:
      return 'トライアル展開準備';
    case 12:
      return 'トライアル開始済み';
    case 19:
      return 'トライアル終了';
    case 20:
      return '本番環境決済済み';
    case 21:
      return '本番環境展開準備';
    case 22:
      return '本番環境開始済み';
    case 30:
      return '契約解除済み';
    case 31:
      return '契約解約完了';
    default:
      return '不明なステータスコードです';
  }
};

export const renameContactType = (status: number): string => {
  switch (status) {
    case 10:
      return 'トライアル中';
    case 20:
      return 'クレジットカード払い';
    case 30:
      return '請求書払い';
    default:
      return '契約解除中';
  }
};
