import { css } from '@emotion/react/macro';
import { FC } from 'react';
import { Grid } from 'semantic-ui-react';

type Props = {
  title: string
  color? : boolean
}
const EntranceTitle:FC<Props> = ({ title, color = false }) => {
  const serviceTitileStyle = css({
    fontSize: '20px',
    background: color ? '#fff' : 'rgb(232, 241, 251) !important',
    h1: {
      fontSize: '25px',
      fontWeight: 'normal',
    },
  });

  return (
    <section css={sectionStyle}>
      <Grid.Row textAlign="center" verticalAlign="middle" css={serviceTitileStyle}>
        <h1>{title}</h1>
      </Grid.Row>
    </section>
  );
};
const sectionStyle = css({
  padding: '80px',
});

export default EntranceTitle;
