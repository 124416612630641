import React from 'react';
import AuthHeader from '../../Organisms/header/AuthHeader';
import IndexLayout from '../../Organisms/IndexLayout';
import LoginFrom from '../../Organisms/Form/LoginForm';
import Footer from '../../Atoms/Footer';

const Login:React.FC = () => (
  <>
    <AuthHeader />
    <IndexLayout odd height={600}>
      <LoginFrom />
    </IndexLayout>
    <Footer odd minHeihght="60px" />
  </>
);
export default Login;
