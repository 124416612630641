import { FC } from 'react';
import { css } from '@emotion/react';
import { Container, Message } from 'semantic-ui-react';

type Props = {
  err: string
}

const ErrorMessage: FC<Props> = ({ err }) => (
  <Container text css={errorStyle}>
    <Message negative><p>{err}</p></Message>
  </Container>
);

const errorStyle = css`
  margin-top: 30px;
`;

export default ErrorMessage;
