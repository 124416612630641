/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ReactNode } from 'react';
import { PutTenantValidation, putTenatValidType } from '../util/validate/PutTenantInfoValidation';
import { createAxiosInstance } from './repository';
import { SettingModalError, SettingModalMessage } from '../text/error';

type getApiType = {
  status: string,
  result: postTenantInfoType,
};

type postTenantInfoType = {
  sharedlink_autopassword_enable: number;
  sharedlink_password_length: string;
  admin_mail_address: string;
  notice_for_admin_enable: number;
  notice_for_owner_enable: number;
  slack_webhook_enable: number;
  slack_webhook_url: string;
  notice_mail_body: string;
  notice_mail_title: string;
  sharedlink_black_white: number;
  sharedlink_black_list: string;
  sharedlink_black_id_list: string;
  sharedlink_white_list: string;
  sharedlink_white_id_list: string;
};

export type tenantInfoType = {
  sharedlinkAutopasswordEnable: number;
  sharedlinkPasswordLength: string;
  adminMailAddress: string;
  noticeForAdminEnable: number;
  noticeForOwnerEnable: number;
  slackWebhookEnable: number;
  slackWebhookUrl: string;
  noticeMailBody: string;
  noticeMailTitle: string;
  sharedlinkBlackWhite: number;
  sharedlinkBlackList: string;
  sharedlinkBlackIdList: string;
  sharedlinkWhiteList: string;
  sharedlinkWhiteIdList: string;
};
type TenantRepositoryType = {
  get: () => Promise<tenantInfoType>;
  post: (
    body: tenantInfoType,
    setErr: React.Dispatch<React.SetStateAction<putTenatValidType>>,
    setContent: React.Dispatch<React.SetStateAction<string | ReactNode>>,
  ) => Promise<boolean>;
};

export const TenantRepository = (tenantAlias: string, idtoken?: string): TenantRepositoryType => {
  const instace = createAxiosInstance(idtoken);

  const get = async (): Promise<tenantInfoType> => {
    const res = await instace.get<getApiType>(`/${tenantAlias}`);
    const { data } = res;
    const perseJson = data.result;

    return {
      sharedlinkAutopasswordEnable: perseJson.sharedlink_autopassword_enable,
      sharedlinkPasswordLength: perseJson.sharedlink_password_length,
      adminMailAddress: perseJson.admin_mail_address,
      noticeForAdminEnable: perseJson.notice_for_admin_enable,
      noticeForOwnerEnable: perseJson.notice_for_owner_enable,
      slackWebhookEnable: perseJson.slack_webhook_enable,
      slackWebhookUrl: perseJson.slack_webhook_url === null ? '' : perseJson.slack_webhook_url,
      noticeMailBody: perseJson.notice_mail_body,
      noticeMailTitle: perseJson.notice_mail_title,
      sharedlinkBlackWhite: perseJson.sharedlink_black_white,
      sharedlinkBlackList: perseJson.sharedlink_black_list,
      sharedlinkBlackIdList: perseJson.sharedlink_black_id_list,
      sharedlinkWhiteList: perseJson.sharedlink_white_list,
      sharedlinkWhiteIdList: perseJson.sharedlink_white_id_list,
    };
  };

  const post = async (
    body: tenantInfoType,
    setErr: React.Dispatch<React.SetStateAction<putTenatValidType>>,
    setContent: React.Dispatch<React.SetStateAction<string | ReactNode>>,
  ): Promise<boolean> => {
    const postBody: postTenantInfoType = {
      sharedlink_autopassword_enable: body.sharedlinkAutopasswordEnable,
      sharedlink_password_length: body.sharedlinkPasswordLength,
      admin_mail_address: body.adminMailAddress,
      notice_for_admin_enable: body.noticeForAdminEnable,
      notice_for_owner_enable: body.noticeForOwnerEnable,
      slack_webhook_enable: body.slackWebhookEnable,
      slack_webhook_url: body.slackWebhookUrl,
      notice_mail_body: body.noticeMailBody,
      notice_mail_title: body.noticeMailTitle,
      sharedlink_black_white: body.sharedlinkBlackWhite,
      sharedlink_black_list: body.sharedlinkBlackList,
      sharedlink_black_id_list: body.sharedlinkBlackIdList,
      sharedlink_white_list: body.sharedlinkWhiteList,
      sharedlink_white_id_list: body.sharedlinkWhiteIdList,
    };
    try {
      await instace.put(`/${tenantAlias}`, postBody);

      return true;
    } catch (e) {
      if (e.response.status === 400) {
        const valid = new PutTenantValidation();
        valid.setErrorMassage(e.response.data.messages);
        setErr(valid.getObj());
        setContent(SettingModalError.inputError);
      } else {
        setContent(SettingModalMessage);
      }

      return false;
    }
  };

  return { get, post };
};
