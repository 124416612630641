import { FC, ReactNode } from 'react';
import { css } from '@emotion/react/macro';
import { Form } from 'semantic-ui-react';
import SettingText from '../Atoms/setting/SettingText';

type Props = {
  title: string,
  children: ReactNode,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  placeholder: string,
  error: boolean,
  errorContent: string
}

const SettingForm: FC<Props> = ({
  title, children, value, setValue, placeholder, error, errorContent,
}) => (
  <Form.Group css={borderStyle}>
    <Form.Field width="16" css={fieldStyle}>
      <h5>{title}</h5>
      <SettingText marginTp="10px">
        {children}
      </SettingText>
      <Form.Input
        fluid
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        css={placeholderPosition}
        error={error && errorContent}
      />
    </Form.Field>
  </Form.Group>
);

const borderStyle = css({
  borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
});

const fieldStyle = css({
  marginBottom: '20px !important',
});

const placeholderPosition = css`
  margin-bottom: 20px;
  input {
    ::placeholder  {
      text-align: right;
    }
  }
`;

export default SettingForm;
