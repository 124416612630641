import { css } from '@emotion/react';
import { FC, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Container, Image, Grid } from 'semantic-ui-react';
import NotFoundImage from '../../../assets/illust_404.svg';
import { URL } from '../../../util';
import AuthHeader from '../../Organisms/header/AuthHeader';

const NotFound:FC = () => {
  useEffect(() => {
    document.title = 'GrantDrive | NotFound';
  }, []);

  return (
    <>
      <div>
        <AuthHeader />
        <Container text textAlign="justified">
          <Image
            src={NotFoundImage}
            css={imageStyle}
          />
          <Grid textAlign="center" css={GridStyle}>
            <Grid.Row textAlign="center">
              <h3 css={blueColor}>お探しのページが見つかりませんでした。</h3>
            </Grid.Row>
            <Grid.Row textAlign="center">
              申し訳ございませんが、お探しのページが存在しません。
            </Grid.Row>
            <Grid.Row textAlign="center" as="a">
              <Link to={URL.index}>
                TOPページへ戻る
              </Link>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const blueColor = css`
  color: #224EA8
`;
const GridStyle = css`
  margin-top: 20px;
`;

const imageStyle = css`
  position: relative;
  margin-top: 70px;
  margin-left: 20px;
  margin-bottom: 40px;
`;

export default NotFound;
