import { css, SerializedStyles } from '@emotion/react/macro';
import { memo, FC, ReactNode } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import SettingText from '../Atoms/setting/SettingText';

type Props = {
  title: string,
  children: ReactNode,
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  togglePosition?: SerializedStyles
}

const SettingToggle: FC<Props> = memo(({
  title, children, value, setValue, togglePosition,
}) => {
  const onChangeHandle = (e:React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    if (value === 0) {
      setValue(1);
    } else {
      setValue(0);
    }
  };

  return (
    <>
      <h5>{title}</h5>
      <Form.Group css={borderStyle}>
        <Form.Field width="14">
          <SettingText>
            {children}
          </SettingText>
        </Form.Field>
        <Form.Field width="2">
          <Radio
            toggle
            css={togglePosition}
            checked={value === 1}
            onChange={(e) => onChangeHandle(e)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
});

const borderStyle = css({
  borderBottom: '1px solid rgba(34, 36, 38, 0.15)',
});
export default SettingToggle;
