/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { config } from './config';

export const stripe: stripe.Stripe = Stripe(config.stripeKey);
const elements = stripe.elements();
const style = {
  base: {
    iconColor: '#c4f0ff',
    fontWeight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
};
const card = elements.create('card', { style });

export { card };
