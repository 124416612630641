import { FC, useContext, useState } from 'react';
import { css } from '@emotion/react/macro';
import { Container, Segment } from 'semantic-ui-react';
import { Context } from '../../../context/ContextProvider';
import { useGetEventsState } from '../../../hooks/useGetEventsState';
import EventsStateTable from '../../Molecules/EventsStateTable';
import Loading from '../../Atoms/Loading';
import SegmentHeader from '../../Atoms/setting/SegmentHeader';

const EventState:FC = () => {
  const { idtoken, tenantAlias } = useContext(Context);
  const [loading, setIsLoading] = useState(true);
  const { events, err } = useGetEventsState(idtoken, tenantAlias, setIsLoading);

  return (
    <Container css={segmntMargin}>
      <Segment color="blue" css={segmentWidth} style={{ margin: '0 auto' }}>
        <SegmentHeader rightText="直近10件のパスワード設定状況については下記の通りです。">
          直近の作動状況
        </SegmentHeader>
        {err && (<p>{err}</p>)}
        {loading && (<Loading />)}
        { !err && !loading && (
        <EventsStateTable eventLog={events} />
        )}
      </Segment>
    </Container>
  );
};

const segmntMargin = css({
  marginTop: '15px',
});

const segmentWidth = css({
  maxWidth: '800px',
});

export default EventState;
