import { FC } from 'react';
import StripeCredit from '../../Organisms/dashBoard/StripeCredit';
import PortalHeader from '../../Organisms/header/PortalHeader';
import Footer from '../../Atoms/Footer';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}
const Credit:FC<Props> = ({ active, setActive }) => (
  <>
    <PortalHeader active={active} setActive={setActive} />
    <StripeCredit />
    <Footer odd />
  </>
);

export default Credit;
