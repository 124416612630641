export const MainSecondExpansionText = {
  first: {
    title: '常に、安心・安全',
    description: ['常にパスワードを付与する為、情報漏洩のリスクを減らすことができます。'],
  },
  second: {
    title: '共有作業が楽にできる',
    description: [
      '設定いただいた後は、共有リンクを検知して、システムが自動的にパスワードを設定・通知します。',
      '利用者によるパスワードの設定漏れの心配もありません。',
      'リラックスして作業が行えます。',
    ],
  },
  therd: {
    title: ['個別のインフラ環境コストは', '必要ありません'],
    description: [
      '本サービスはcloudサービスとしてご提供します。',
      'お客様に個別のインフラ環境をご用意いただく必要はありません。',
      '通常通りWEBブラウザ、BoxDriveスマホ等にて共有リンクを作成してご利用いただけます。',
    ],
  },
};
