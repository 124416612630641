import {
  FC, useContext, useState,
  useEffect,
} from 'react';
import { css } from '@emotion/react/macro';
import ContractInfo from '../../Organisms/dashBoard/ContractInfo';
import PortalHeader from '../../Organisms/header/PortalHeader';
import ServiceState from '../../Organisms/dashBoard/ServiceState';
import EventState from '../../Organisms/dashBoard/EventState';
import { useGetContactInfo } from '../../../hooks';
import { Context } from '../../../context/ContextProvider';
import Footer from '../../Atoms/Footer';
import ErrorMassage from '../../Molecules/ErrorMessage';

type Props = {
  active: string,
  setActive: React.Dispatch<React.SetStateAction<string>>
}

const Dashboard: FC<Props> = ({ active, setActive }) => {
  const { idtoken, tenantAlias } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const { contactInfo, err } = useGetContactInfo(setLoading, tenantAlias, idtoken);
  useEffect(() => {
    setActive('dashboard');
  }, [setActive]);

  return (
    <>
      <PortalHeader active={active} setActive={setActive} />
      <div css={dashBoad}>
        {err && (<ErrorMassage err={err} />)}
        {!err
        && <ContractInfo contactInfo={contactInfo} loading={loading} err={err} />}
        {
      (contactInfo.tenantStatus === 12
      || contactInfo.tenantStatus === 22)
      && (
        <>
          <ServiceState />
          <EventState />
        </>
      )
      }

      </div>
      <Footer odd minHeihght="0px" />
    </>
  );
};

const dashBoad = css`
  min-height: calc(100vh - 150px);
`;

export default Dashboard;
