import { memo, FC } from 'react';
import { css } from '@emotion/react/macro';

type Props = {
  children: React.ReactNode
  marginTp: string,
  marginBt: string,
  border? : boolean,
  width?: string
}
const AuthText: FC<Props> = memo(({
  marginBt, marginTp, border, children, width,
}) => {
  const changePasswordStyle = css({
    marginTop: marginTp,
    textAlign: 'center',
    marginBottom: marginBt,
    width: width !== '' ? width : '100%',
  });

  const hstyle = css({
    borderBottom: border ? '1px solid rgb(188,188,188)' : '',
    display: 'inline-block',
    fontSize: '12px',
  });

  return (
    <div css={changePasswordStyle}>
      <div css={hstyle}>{children}</div>
    </div>
  );
});

export default AuthText;
