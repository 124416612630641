/* eslint-disable @typescript-eslint/no-unsafe-member-access,no-console */

import { useEffect, useState } from 'react';
import { TenantRepository } from '../Repository/TenantRepository';

type useGetTenantInfoType = {
  sharedlinkAutopasswordEnable: number;
  setSharedlinkAutopasswordEnable: React.Dispatch<React.SetStateAction<number>>;
  noticeForAdminEnable: number;
  setNoticeForAdminEnable: React.Dispatch<React.SetStateAction<number>>;
  sharedlinkPasswordLength: string;
  setSharedlinkPasswordLength: React.Dispatch<React.SetStateAction<string>>;
  noticeForOwnerEnable: number;
  setNoticeForOwnerEnable: React.Dispatch<React.SetStateAction<number>>;
  adminMailAddress: string;
  setAdminMailAddress: React.Dispatch<React.SetStateAction<string>>;
  slackWebhookEnable: number;
  setSlackWebhookEnable: React.Dispatch<React.SetStateAction<number>>;
  slackWebhookUrl: string;
  setSlackWebhookUrl: React.Dispatch<React.SetStateAction<string>>;
  noticeMailBody: string;
  setNoticeMailBody: React.Dispatch<React.SetStateAction<string>>;
  noticeMailTitle: string;
  setNoticeMailTitle: React.Dispatch<React.SetStateAction<string>>;
  sharedlinkBlackWhite: number;
  setSharedlinkBlackWhite: React.Dispatch<React.SetStateAction<number>>;
  sharedlinkBlackList: string[];
  setSharedlinkBlackList: React.Dispatch<React.SetStateAction<string[]>>;
  sharedlinkBlackIdList: string[];
  setSharedlinkBlackIdList: React.Dispatch<React.SetStateAction<string[]>>;
  sharedlinkWhiteList: string[];
  setSharedlinkWhiteList: React.Dispatch<React.SetStateAction<string[]>>;
  sharedlinkWhiteIdList: string[];
  setSharedlinkWhiteIdList: React.Dispatch<React.SetStateAction<string[]>>;
  getError: string;
};
export const useGetTenantInfo = (
  setIsloading: React.Dispatch<React.SetStateAction<boolean>>,
  tenantAlias: string,
  idToken: string | undefined,
): useGetTenantInfoType => {
  const [sharedlinkAutopasswordEnable, setSharedlinkAutopasswordEnable] = useState(0);
  const [sharedlinkPasswordLength, setSharedlinkPasswordLength] = useState('');
  const [adminMailAddress, setAdminMailAddress] = useState('');
  const [noticeForAdminEnable, setNoticeForAdminEnable] = useState(0);
  const [noticeForOwnerEnable, setNoticeForOwnerEnable] = useState(0);
  const [slackWebhookEnable, setSlackWebhookEnable] = useState(0);
  const [slackWebhookUrl, setSlackWebhookUrl] = useState('');
  const [noticeMailBody, setNoticeMailBody] = useState('');
  const [noticeMailTitle, setNoticeMailTitle] = useState('');
  const [sharedlinkBlackWhite, setSharedlinkBlackWhite] = useState(1);
  const [sharedlinkBlackList, setSharedlinkBlackList] = useState<
    string[]
  >([]);
  const [sharedlinkBlackIdList, setSharedlinkBlackIdList] = useState<
    string[]
  >([]);
  const [sharedlinkWhiteList, setSharedlinkWhiteList] = useState<
    string[]
  >([]);
  const [sharedlinkWhiteIdList, setSharedlinkWhiteIdList] = useState<
    string[]
  >([]);
  const [getError, setGetError] = useState('');

  useEffect(() => {
    void TenantRepository(tenantAlias, idToken)
      .get()
      .then((data) => {
        setSharedlinkAutopasswordEnable(data.sharedlinkAutopasswordEnable);
        setNoticeForAdminEnable(data.noticeForAdminEnable);
        setSharedlinkPasswordLength(data.sharedlinkPasswordLength);
        setNoticeForOwnerEnable(data.noticeForOwnerEnable);
        setAdminMailAddress(data.adminMailAddress);
        setSlackWebhookEnable(data.slackWebhookEnable);
        setSlackWebhookUrl(data.slackWebhookUrl);
        setNoticeMailBody(data.noticeMailBody);
        setNoticeMailTitle(data.noticeMailTitle);
        setSharedlinkBlackWhite(data.sharedlinkBlackWhite);
        setSharedlinkBlackList(
          data.sharedlinkBlackList !== ''
            ? data.sharedlinkBlackList.split(',')
            : [],
        );
        setSharedlinkBlackIdList(
          data.sharedlinkBlackIdList !== ''
            ? data.sharedlinkBlackIdList.split(',')
            : [],
        );
        setSharedlinkWhiteList(
          data.sharedlinkWhiteList !== ''
            ? data.sharedlinkWhiteList.split(',')
            : [],
        );
        setSharedlinkWhiteIdList(
          data.sharedlinkWhiteIdList !== ''
            ? data.sharedlinkWhiteIdList.split(',')
            : [],
        );
        setIsloading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setGetError('サービス設定を取得できませんでした。しばらくしてからお試しください。');
        setIsloading(false);
      });
  }, [idToken, setIsloading, tenantAlias]);

  return {
    sharedlinkAutopasswordEnable,
    sharedlinkPasswordLength,
    adminMailAddress,
    noticeForAdminEnable,
    noticeForOwnerEnable,
    noticeMailBody,
    setNoticeMailBody,
    slackWebhookEnable,
    slackWebhookUrl,
    noticeMailTitle,
    setNoticeMailTitle,
    setSharedlinkAutopasswordEnable,
    setSharedlinkPasswordLength,
    setAdminMailAddress,
    setNoticeForAdminEnable,
    setNoticeForOwnerEnable,
    setSlackWebhookEnable,
    setSlackWebhookUrl,
    sharedlinkBlackWhite,
    setSharedlinkBlackWhite,
    sharedlinkBlackList,
    setSharedlinkBlackList,
    sharedlinkBlackIdList,
    setSharedlinkBlackIdList,
    sharedlinkWhiteList,
    setSharedlinkWhiteList,
    sharedlinkWhiteIdList,
    setSharedlinkWhiteIdList,
    getError,
  };
};
