/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable camelcase */
import { renameStatus } from '../util';
import { createAxiosInstance } from './repository';
import { ContactValidation, contactValidType } from '../util/validate/ContactValidation';

type ContactApiType = {
  inquiry_type: number | undefined;
  name: string;
  name_kana: string;
  organization_name: string;
  email: string;
  phone_number: string;
  box_account_id_count?: string;
  enterprise_id?: string;
  question?: string;
  agree_flag: number;
};
type ContractInfoApiType = {
  tenant_maximum_account: string;
  tenant_contract_start: string;
  tenant_initial_cost: number;
  stripe_subscription_id: string;
  tenant_contract_type: number;
  tenant_id: string;
  tenant_grantdrive_price: number;
  stripe_price_id: string;
  tenant_contract_end: string;
  tenant_status: number;
};
export type getCntractInfo = {
  tenantMaximumAccount: string;
  tenantContractStart: string;
  tenantContractEnd?: string;
  tenantContractType: string;
  tenantStatus: number;
  tenantStatusName: string;
};

type contactArgs = {
  contactType: string;
  name: string;
  kanaName: string;
  company: string;
  mail: string;
  phoneNumber: string;
  boxUserCount?: string;
  boxId?: string;
  question?: string;
};

type ContactRepositoryType = {
  post: (
    body: contactArgs,
    setErr: React.Dispatch<React.SetStateAction<contactValidType>>,
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    setContent: React.Dispatch<React.SetStateAction<string>>,
  ) => Promise<boolean>;
  stopService: (tenantAlias: string) => Promise<boolean>;
  getContractInfo: (tenantAlias: string) => Promise<getCntractInfo>;
};
export const ContactRepository = (idToken?: string): ContactRepositoryType => {
  const instace = createAxiosInstance(idToken);
  const post = async (
    body: contactArgs,
    setErr: React.Dispatch<React.SetStateAction<contactValidType>>,
    setTitle: React.Dispatch<React.SetStateAction<string>>,
    setContent: React.Dispatch<React.SetStateAction<string>>,
  ): Promise<boolean> => {
    const { kanaName, name, company, phoneNumber, mail, boxUserCount, boxId, question } = body;

    let cotnactType: number | undefined;
    if (body.contactType === 'トライアル利用') cotnactType = 1;
    if (body.contactType === 'サービスに関するご質問') cotnactType = 0;
    const reqData: ContactApiType = {
      inquiry_type: cotnactType,
      name,
      name_kana: kanaName,
      phone_number: phoneNumber,
      email: mail,
      organization_name: company,
      agree_flag: 1,
    };
    if (boxUserCount) {
      reqData.box_account_id_count = boxUserCount;
    }
    if (boxId) {
      reqData.enterprise_id = boxId;
    }
    if (question) {
      reqData.question = question;
    }
    try {
      await instace.post<ContactApiType>('', reqData);

      return true;
    } catch (e) {
      if (e.response.status === 400) {
        const valid = new ContactValidation();
        valid.setErrorMassage(e.response.data.messages);
        setErr(valid.getContactObj());
        setTitle('入力エラー');
        setContent('入力項目に不備があります。');
      } else if (e.responce.status === 500) {
        setTitle('サーバーエラー');
        setContent('申し訳ありませんが、しばらくしてから再度お試しください。');
      }

      return false;
    }
  };

  const stopService = async (tenantAlias: string) => {
    const { status } = await instace.delete(`${tenantAlias}/contract`);
    if (status !== 200) return false;

    return true;
  };

  const getContractInfo = async (tenantAlias: string) => {
    const { data } = await instace.get<ContractInfoApiType>(`${tenantAlias}/contract-info`);
    let contractType = '';
    switch (data.tenant_contract_type) {
      case 20:
        contractType = 'Stripe';
        break;
      case 10:
        contractType = 'トライアル';
        break;
      default:
        contractType = '請求書払い';
        break;
    }
    const responceData: getCntractInfo = {
      tenantMaximumAccount: data.tenant_maximum_account,
      tenantContractStart: data.tenant_contract_start,
      tenantContractType: contractType,
      tenantStatus: data.tenant_status,
      tenantStatusName: renameStatus(data.tenant_status),
    };
    if (data.tenant_contract_end !== '2200-01-01 00:00:00') {
      responceData.tenantContractEnd = data.tenant_contract_end;
    }

    return responceData;
  };

  return { post, stopService, getContractInfo };
};
