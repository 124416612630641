import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { Header, Table, Label } from 'semantic-ui-react';
import { css } from '@emotion/react/macro';

const InitialCost = 50000;
const BaseCost = 5000;
const UnitCost = 250;
const tax = 0.1;

type Props ={
  userCount: number,
  isFirst?: boolean,
}

const sumTotalCost = (userCount: number, isFirst: boolean) => {
  let totalCost = UnitCost * userCount + BaseCost;

  if (isFirst) {
    totalCost += InitialCost;
  }

  return totalCost;
};

const PaymentDetailsTable: FC<Props> = ({ userCount, isFirst = false }) => {
  const totalCost = sumTotalCost(userCount, isFirst);
  const totalCostIncludeTax = totalCost * tax + totalCost;

  return (
    <Table basic>
      <Table.Body>
        {
          isFirst && (
            <Table.Row>
              <Table.Cell>
                <Header as="h4">
                  初期費用
                  <Label as="a" color="red" size="mini" horizontal>
                    初回のみ
                  </Label>
                </Header>
              </Table.Cell>
              <Table.Cell textAlign="right">
                <NumberFormat
                  value={InitialCost}
                  displayType="text"
                  thousandSeparator=","
                  prefix="¥ "
                />
              </Table.Cell>
            </Table.Row>
          )
        }
        <Table.Row>
          <Table.Cell>
            <Header as="h4">
              基本料金
            </Header>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <NumberFormat
              value={BaseCost}
              displayType="text"
              thousandSeparator=","
              prefix="¥ "
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <Header sub style={{ 'font-size': '15px' }}>
              月額料金
            </Header>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <NumberFormat
              value={UnitCost * userCount}
              displayType="text"
              thousandSeparator=","
              prefix="¥ "
            />
            <p style={{ color: 'gray' }}>
              ユーザー単価：
              <NumberFormat
                value={UnitCost}
                displayType="text"
                thousandSeparator=","
                prefix="¥ "
              />
              {' '}
              x
              {' '}
              {userCount}
              {' '}
              ユーザー
            </p>
          </Table.Cell>
        </Table.Row>
      </Table.Body>

      <Table.Row css={totalBackground}>
        <Table.Cell css={totalStyle}>
          <Header as="h3">
            請求金額
          </Header>
        </Table.Cell>
        <Table.Cell textAlign="right" css={totalStyle}>
          <NumberFormat
            value={totalCostIncludeTax}
            displayType="text"
            thousandSeparator=","
            prefix="¥ "
          />
          {' '}
          (税抜
          {' '}
          <NumberFormat
            value={totalCost}
            displayType="text"
            thousandSeparator=","
            prefix="¥ "
          />
          、消費税
          {' '}
          <NumberFormat
            value={totalCost * tax}
            displayType="text"
            thousandSeparator=","
            prefix="¥ "
          />
          )
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};

const totalBackground = css({
  'background-color': '#eee !important',
});
const totalStyle = css({
  'padding-top': '25px !important',
  'padding-bottom': '25px !important',
  'font-size': '20px',
  'font-weight': 'bolder',
  'white-space': 'nowrap',
});

export default PaymentDetailsTable;
