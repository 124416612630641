import { FC, useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { AccountContext } from '../../context/AccountProvider';

type Props ={
  onClickFunc: () => void
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  loading: boolean
  isDeleted: boolean,
  content: string
}

const DeleteDialog: FC<Props> = ({
  onClickFunc, open, loading, setOpen, isDeleted, content,
}) => {
  const { setIsAccountDelete, isAccountDelete } = useContext(AccountContext);

  const CloseModal = () => {
    setIsAccountDelete(!isAccountDelete);
    setOpen(false);
  };

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>
        ユーザー削除
      </Modal.Header>
      <Modal.Content>
        { isDeleted ? 'ユーザーを削除しました。' : content}
      </Modal.Content>
      <Modal.Actions>
        {
        isDeleted && <Button onClick={CloseModal} primary>戻る</Button>
      }
        {
        !isDeleted && <Button onClick={() => setOpen(false)} primary>戻る</Button>
      }
        {
        !isDeleted
        && (<Button disabled={loading} onClick={onClickFunc} color="red">削除</Button>)
      }
      </Modal.Actions>
    </Modal>
  );
};
export default DeleteDialog;
